import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { doc, setDoc, updateDoc, getDoc, collection, addDoc, arrayUnion, getDocs, deleteDoc, query, orderBy, where } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { generateResponse } from '../services/openai'; // Make sure this import is correct

const ADMIN_ID = '2EONAtrLQIbr3VYY5JSTzOvgcso2';

const sanitizeMessage = (message) => {
  return JSON.parse(JSON.stringify(message));
};

export const fetchUserData = createAsyncThunk(
  'chat/fetchUserData',
  async (userId) => {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);
    const conversationsSnapshot = await getDocs(query(collection(db, `users/${userId}/conversations`), orderBy('createdAt', 'desc')));
    const agentsSnapshot = await getDocs(collection(db, `users/${userId}/agents`));
    
    const conversations = {};
    conversationsSnapshot.forEach(doc => {
      conversations[doc.id] = { id: doc.id, ...doc.data() };
    });
    
    const agents = [];
    agentsSnapshot.forEach(doc => {
      agents.push({ id: doc.id, ...doc.data() });
    });

    if (userDoc.exists()) {
      const userData = userDoc.data();
      return { profile: userData.profile || {}, conversations, agents };
    }
    return { profile: {}, conversations, agents };
  }
);

export const fetchAgents = createAsyncThunk(
  'chat/fetchAgents',
  async (_, { rejectWithValue }) => {
    try {
      const agentsRef = collection(db, `users/${ADMIN_ID}/agents`);
      const querySnapshot = await getDocs(agentsRef);
      const agents = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      return agents;
    } catch (error) {
      return rejectWithValue(error.message || 'An error occurred while fetching agents');
    }
  }
);

export const createNewConversation = createAsyncThunk(
  'chat/createNewConversation',
  async (userId) => {
    const newConversationRef = await addDoc(collection(db, `users/${userId}/conversations`), {
      messages: [],
      createdAt: new Date().toISOString(),
      title: 'New Conversation'
    });
    return { id: newConversationRef.id, messages: [], createdAt: new Date().toISOString(), title: 'New Conversation' };
  }
);

export const addMessageAndGenerateResponse = createAsyncThunk(
  'chat/addMessageAndGenerateResponse',
  async ({ userId, conversationId, message, systemInstruction, userProfile }, { dispatch, getState }) => {
    const user = auth.currentUser;
    if (!user) {
      throw new Error('User not authenticated');
    }

    const sanitizedMessage = sanitizeMessage(message);
    
    let targetConversationId = conversationId;
    let isNewConversation = false;
    if (!targetConversationId) {
      const newConversation = await dispatch(createNewConversation(userId)).unwrap();
      targetConversationId = newConversation.id;
      isNewConversation = true;
    }

    const conversationRef = doc(db, `users/${userId}/conversations`, targetConversationId);
    const conversationDoc = await getDoc(conversationRef);

    let title = conversationDoc.exists() ? conversationDoc.data().title : "New Conversation";
    if ((isNewConversation || title === "New Conversation") && message.role === 'user' && message.content) {
      title = message.content.slice(0, 30) + (message.content.length > 30 ? '...' : '');
    }

    let conversationHistory = conversationDoc.exists() ? conversationDoc.data().messages : [];
    conversationHistory.push(sanitizedMessage);

    console.log('Conversation history:', conversationHistory);

    // Start three operations in parallel
    const [firestorePromise, aiResponsePromise] = await Promise.all([
      // 1. Add message to Firestore
      updateDoc(conversationRef, {
        messages: arrayUnion(sanitizedMessage),
        title: title
      }),
      
      // 2. Generate AI response
      generateResponse(
        conversationHistory,
        systemInstruction,
        userProfile,
        message.images,
        (partialResponse) => {
          dispatch(updateStreamingMessage(partialResponse));
        },
        userId
      )
    ]);

    const { aiResponse, memoryOperation } = await aiResponsePromise;

    // 3. Add AI response to Firestore
    await updateDoc(conversationRef, {
      messages: arrayUnion({
        role: 'assistant',
        content: aiResponse,
        timestamp: new Date().toISOString()
      })
    });

    // Fetch the updated conversation to ensure we have the correct data
    const updatedConversationDoc = await getDoc(conversationRef);
    const updatedConversation = updatedConversationDoc.data();

    return { 
      conversationId: targetConversationId,
      userMessage: sanitizedMessage,
      aiResponse: {
        role: 'assistant',
        content: aiResponse,
        timestamp: new Date().toISOString()
      },
      title: updatedConversation.title,
      isNewConversation,
      memoryOperation
    };
  }
);

export const updateConversationTitle = createAsyncThunk(
  'chat/updateConversationTitle',
  async ({ userId, conversationId, title }) => {
    const conversationRef = doc(db, `users/${userId}/conversations`, conversationId);
    await updateDoc(conversationRef, { title });
    return { conversationId, title };
  }
);

export const updateAgent = createAsyncThunk(
  'chat/updateAgent',
  async ({ userId, agentId, agentData }) => {
    const agentRef = doc(db, `users/${userId}/agents`, agentId);
    await updateDoc(agentRef, agentData);
    return { id: agentId, ...agentData };
  }
);

export const deleteConversation = createAsyncThunk(
  'chat/deleteConversation',
  async ({ userId, conversationId }) => {
    await deleteDoc(doc(db, `users/${userId}/conversations`, conversationId));
    return conversationId;
  }
);

export const updateUserProfile = createAsyncThunk(
  'chat/updateUserProfile',
  async ({ userId, profileData }) => {
    const userRef = doc(db, 'users', userId);
    await setDoc(userRef, { profile: profileData }, { merge: true });
    return profileData;
  }
);

export const searchConversations = createAsyncThunk(
  'chat/searchConversations',
  async ({ userId, searchTerm }) => {
    const conversationsRef = collection(db, `users/${userId}/conversations`);
    const q = query(conversationsRef);
    const querySnapshot = await getDocs(q);
    
    const conversations = {};
    querySnapshot.forEach(doc => {
      const conversationData = doc.data();
      const messages = conversationData.messages || [];
      const matchingMessages = messages.filter(msg => 
        msg.content && msg.content.toLowerCase().includes(searchTerm.toLowerCase())
      );
      if (matchingMessages.length > 0) {
        conversations[doc.id] = { 
          id: doc.id, 
          ...conversationData,
          messages: matchingMessages // Only include matching messages
        };
      }
    });
    
    return conversations;
  }
);

export const deleteAgent = createAsyncThunk(
  'chat/deleteAgent',
  async ({ userId, agentId }) => {
    await deleteDoc(doc(db, `users/${userId}/agents`, agentId));
    return agentId;
  }
);

export const pinAgent = createAsyncThunk(
  'chat/pinAgent',
  async ({ userId, agentId }) => {
    const agentRef = doc(db, `users/${userId}/agents`, agentId);
    await updateDoc(agentRef, { isPinned: true });
    return agentId;
  }
);

export const unpinAgent = createAsyncThunk(
  'chat/unpinAgent',
  async ({ userId, agentId }) => {
    const agentRef = doc(db, `users/${userId}/agents`, agentId);
    await updateDoc(agentRef, { isPinned: false });
    return agentId;
  }
);

export const deleteAllConversations = createAsyncThunk(
  'chat/deleteAllConversations',
  async (userId) => {
    const conversationsRef = collection(db, `users/${userId}/conversations`);
    const querySnapshot = await getDocs(conversationsRef);
    
    const deletePromises = querySnapshot.docs.map(doc => 
      deleteDoc(doc.ref)
    );
    
    await Promise.all(deletePromises);
    
    return {};
  }
);

export const editMessage = createAsyncThunk(
  'chat/editMessage',
  async ({ userId, conversationId, messageIndex, newContent }) => {
    const conversationRef = doc(db, `users/${userId}/conversations`, conversationId);
    const conversationDoc = await getDoc(conversationRef);

    if (conversationDoc.exists()) {
      const messages = conversationDoc.data().messages;
      messages[messageIndex].content = newContent;
      
      await updateDoc(conversationRef, {
        messages: messages.slice(0, messageIndex + 1) // Keep messages up to and including the edited one
      });

      return { conversationId, messages: messages.slice(0, messageIndex + 1) };
    }

    throw new Error('Conversation not found');
  }
);

export const shareAgent = createAsyncThunk(
  'chat/shareAgent',
  async ({ agentId, userId }, { rejectWithValue }) => {
    try {
      const agentRef = doc(db, `users/${ADMIN_ID}/agents`, agentId);
      const agentSnapshot = await getDoc(agentRef);

      if (!agentSnapshot.exists()) {
        throw new Error('Agent not found');
      }

      const agentData = agentSnapshot.data();

      const userAgentRef = doc(db, `users/${userId}/agents`, agentId);
      await setDoc(userAgentRef, { ...agentData, sharedBy: ADMIN_ID, sharedWith: userId });

      return { id: agentId, ...agentData };
    } catch (error) {
      return rejectWithValue(error.message || 'An error occurred while sharing the agent');
    }
  }
);

const initialState = {
  conversations: {},
  currentConversation: null,
  agents: [],
  currentAgent: null,
  userProfile: {},
  status: 'idle',
  error: null,
  streamingMessage: '',
  searchResults: {},
  editingMessageIndex: null,
  pendingUserMessage: null,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setCurrentConversation: (state, action) => {
      state.currentConversation = action.payload;
    },
    setCurrentAgent: (state, action) => {
      state.currentAgent = action.payload;
    },
    updateStreamingMessage: (state, action) => {
      state.streamingMessage = action.payload;
    },
    clearStreamingMessage: (state) => {
      state.streamingMessage = '';
    },
    clearSearchResults: (state) => {
      state.searchResults = {};
    },
    setEditingMessageIndex: (state, action) => {
      state.editingMessageIndex = action.payload;
    },
    setPendingUserMessage: (state, action) => {
      state.pendingUserMessage = action.payload;
    },
    clearPendingUserMessage: (state) => {
      state.pendingUserMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.conversations = action.payload.conversations || {};
        state.agents = action.payload.agents || [];
        state.userProfile = action.payload.profile || {};
        if (Object.keys(state.conversations).length === 0) {
          createNewConversation(action.payload.id);
        } else {
          state.currentConversation = Object.keys(state.conversations)[0] || null;
        }
      })
      .addCase(fetchAgents.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchAgents.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.agents = action.payload;
        state.error = null;
      })
      .addCase(fetchAgents.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Failed to fetch agents';
      })
      .addCase(shareAgent.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(shareAgent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Failed to share agent';
      })
      .addCase(createNewConversation.fulfilled, (state, action) => {
        state.conversations[action.payload.id] = action.payload;
        state.currentConversation = action.payload.id;
      })
      .addCase(addMessageAndGenerateResponse.pending, (state, action) => {
        if (state.pendingUserMessage) {
          if (!state.conversations[action.meta.arg.conversationId]) {
            state.conversations[action.meta.arg.conversationId] = { 
              id: action.meta.arg.conversationId, 
              messages: [], 
              title: 'New Conversation' 
            };
          }
          state.conversations[action.meta.arg.conversationId].messages.push(state.pendingUserMessage);
        }
      })
      .addCase(addMessageAndGenerateResponse.fulfilled, (state, action) => {
        const { conversationId, userMessage, aiResponse, title, isNewConversation } = action.payload;
        
        state.conversations[conversationId].messages = state.conversations[conversationId].messages.filter(
          msg => msg !== state.pendingUserMessage
        );
        state.conversations[conversationId].messages.push(userMessage);
        state.conversations[conversationId].messages.push(aiResponse);
        
        if (isNewConversation || state.conversations[conversationId].title === 'New Conversation') {
          state.conversations[conversationId].title = title;
        }
        
        state.currentConversation = conversationId;
        state.pendingUserMessage = null;
      })
      .addCase(addMessageAndGenerateResponse.rejected, (state, action) => {
        state.error = action.error.message;
        if (state.pendingUserMessage && action.meta.arg.conversationId) {
          state.conversations[action.meta.arg.conversationId].messages = 
            state.conversations[action.meta.arg.conversationId].messages.filter(
              msg => msg !== state.pendingUserMessage
            );
        }
        state.pendingUserMessage = null;
      })
      .addCase(updateConversationTitle.fulfilled, (state, action) => {
        const { conversationId, title } = action.payload;
        if (state.conversations[conversationId]) {
          state.conversations[conversationId].title = title;
        }
      })
      .addCase(updateAgent.fulfilled, (state, action) => {
        const index = state.agents.findIndex(agent => agent.id === action.payload.id);
        if (index !== -1) {
          state.agents[index] = action.payload;
        }
      })
      .addCase(pinAgent.fulfilled, (state, action) => {
        const agent = state.agents.find(a => a.id === action.payload);
        if (agent) {
          agent.isPinned = true;
        }
      })
      .addCase(unpinAgent.fulfilled, (state, action) => {
        const agent = state.agents.find(a => a.id === action.payload);
        if (agent) {
          agent.isPinned = false;
        }
      })      
      .addCase(deleteConversation.fulfilled, (state, action) => {
        delete state.conversations[action.payload];
        if (state.currentConversation === action.payload) {
          state.currentConversation = Object.keys(state.conversations)[0] || null;
        }
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.userProfile = action.payload;
      })
      .addCase(searchConversations.fulfilled, (state, action) => {
        state.searchResults = action.payload;
      })
      .addCase(deleteAgent.fulfilled, (state, action) => {
        state.agents = state.agents.filter(agent => agent.id !== action.payload);
        if (state.currentAgent === action.payload) {
          state.currentAgent = null;
        }
      })      
      .addCase(deleteAllConversations.fulfilled, (state, action) => {
        state.conversations = {};
        state.currentConversation = null;
      })
      .addCase(editMessage.fulfilled, (state, action) => {
        const { conversationId, messages } = action.payload;
        state.conversations[conversationId].messages = messages;
        state.editingMessageIndex = null;
      })
      .addCase(shareAgent.fulfilled, (state, action) => {
        // Optionally update state if needed
        console.log(`Agent ${action.payload.id} shared successfully`);
      });
  },
});

export const { 
  setCurrentConversation, 
  setCurrentAgent, 
  updateStreamingMessage, 
  clearStreamingMessage,
  clearSearchResults,
  setEditingMessageIndex,
  setPendingUserMessage,
  clearPendingUserMessage,
} = chatSlice.actions;

export default chatSlice.reducer;