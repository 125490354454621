import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

export const fetchUserProfile = createAsyncThunk(
  'user/fetchUserProfile',
  async (userId, { rejectWithValue }) => {
    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (userDoc.exists()) {
        return { id: userId, ...userDoc.data() };
      } else {
        return rejectWithValue('User profile not found');
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchAllUsers = createAsyncThunk(
  'user/fetchAllUsers',
  async (_, { rejectWithValue }) => {
    try {
      const usersSnapshot = await getDocs(collection(db, 'users'));
      const users = usersSnapshot.docs.map(doc => ({
        uid: doc.id,
        email: doc.data().email || 'No email',
        ...doc.data()
      }));
      return users;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const checkAdminStatus = createAsyncThunk(
  'user/checkAdminStatus',
  async (userId, { rejectWithValue }) => {
    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        return { 
          isAdmin: userData.isAdmin || userId === '2EONAtrLQIbr3VYY5JSTzOvgcso2', 
          id: userId 
        };
      } else {
        return rejectWithValue('User not found');
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState: {
    profile: null,
    users: [],
    status: 'idle',
    allUsersStatus: 'idle',
    error: null,
    isAdmin: false,
  },
  reducers: {
    setUserProfile: (state, action) => {
      state.profile = action.payload;
      state.status = 'succeeded';
      state.error = null;
    },
    updateUserProfile: (state, action) => {
      if (state.profile) {
        state.profile = { ...state.profile, ...action.payload };
      }
    },
    clearUserProfile: (state) => {
      state.profile = null;
      state.status = 'idle';
      state.error = null;
      state.isAdmin = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.profile = action.payload;
        state.error = null;
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchAllUsers.pending, (state) => {
        state.allUsersStatus = 'loading';
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.allUsersStatus = 'succeeded';
        state.users = action.payload;
        state.error = null;
      })
      .addCase(fetchAllUsers.rejected, (state, action) => {
        state.allUsersStatus = 'failed';
        state.error = action.payload;
      })
      .addCase(checkAdminStatus.fulfilled, (state, action) => {
        state.isAdmin = action.payload.isAdmin;
        if (state.profile && state.profile.id === action.payload.id) {
          state.profile.isAdmin = action.payload.isAdmin;
        }
      })
      .addCase(checkAdminStatus.rejected, (state, action) => {
        state.error = action.payload;
        state.isAdmin = false;
      });
  },
});

export const { setUserProfile, updateUserProfile, clearUserProfile } = userSlice.actions;

export default userSlice.reducer;