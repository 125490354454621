// store.js

import { configureStore } from '@reduxjs/toolkit';
import chatReducer from './chatSlice';
import taskReducer from './taskSlice';
import userReducer from './userSlice';
import memoriesReducer from './memoriesSlice';
import stickyNotesReducer from './stickyNotesSlice';


export const store = configureStore({
  reducer: {
    chat: chatReducer,
    tasks: taskReducer,
    user: userReducer,
    memories: memoriesReducer,
    stickyNotes: stickyNotesReducer,
  },
});