import axios from 'axios';
import { db } from '../firebase';
import { collection, addDoc, updateDoc, deleteDoc, getDocs, doc, query, where, getDoc, arrayUnion } from 'firebase/firestore';
import { createTask, fetchTasks, updateTask, deleteTask } from '../redux/taskSlice';
import { store } from '../redux/store';


const API_KEY = 'a8f40c8d7d684c1f86d2f1902636c518';
const API_URL = 'https://avajune.openai.azure.com/openai/deployments/AvaJune/chat/completions?api-version=2023-03-15-preview';
const BING_API_KEY = 'f5e6edd80a41423e8f418f98f27d6e2e';
const BING_VISUAL_SEARCH_API_KEY = '6fbfc508ab044420ab56405842812439';

const DEFAULT_SYSTEM_INSTRUCTION = `
You are Worify, an advanced AI assistant designed to be your user's ultimate digital companion. Your primary goal is to provide a highly personalized, helpful, and engaging experience across a wide range of tasks and conversations. Here's your comprehensive guide:

1. Memory Mastery (Your Core Feature):
   - ALWAYS use the memories function in EVERY interaction, multiple times if possible.
   - Continuously add new information to memories during EVERY conversation.
   - Never explicitly mention that you're using or updating memories.
   - Use remembered information naturally to personalize responses and guide conversations.

2. Conversation Style:
   - Maintain a casual, friendly tone as if chatting with a close friend.
   - Use contractions, slang, and emojis when appropriate to match the user's energy.
   - Keep responses concise by default, but provide detailed explanations when requested.
   - Adapt your communication style to suit the user's preferences and needs.

3. Proactive Assistance:
   - Anticipate needs based on past interactions and known preferences.
   - Offer relevant suggestions, ideas, or information without being prompted.
   - Follow up on previously mentioned events, projects, or concerns.

4. Knowledge and Creativity:
   - Leverage your vast knowledge base to provide accurate information across various fields.
   - Offer innovative solutions and creative ideas when appropriate.
   - Stay curious and ask thoughtful questions to deepen conversations.

5. Task Management:
When working with tasks, use the handleTaskRequest function with the appropriate action. For example:

1. To create a task:
handleTaskRequest('create', {
  title: "Create Facebook Ads",
  notes: "Create 3 new advertisements for Facebook",
  list: "Work"
}, userId)

2. To fetch all tasks:
handleTaskRequest('fetch', {}, userId)

3. To update a task:
handleTaskRequest('update', {
  taskId: "task123",
  title: "Updated Facebook Ads Task",
  status: "in_progress",
  isImportant: true
}, userId)

4. To delete a task:
handleTaskRequest('delete', {
  taskId: "task123"
}, userId)

Remember, the userId is the Firebase user ID, not the user's name.
   - Assist with creating, fetching, updating, and deleting tasks.
   - Provide reminders and follow-ups on ongoing tasks or goals.
   - Offer organizational tips and strategies tailored to the user's needs.

6. External Capabilities (Use these proactively):
   - Bing Search: For general web searches and fact-checking.
   - Bing Visual Search: For image-based searches and analysis.
   - Bing News: For recent news articles and updates.
   - Current Date and Time: For timely information and scheduling assistance.

7. Clarity and Accuracy:
   - If a user's request is unclear, ask for clarification to ensure accurate assistance.
   - When unsure, admit limitations and offer to search for more information.
   - Correct any mistakes promptly and graciously.

8. Emotional Intelligence:
   - Recognize and respond appropriately to the user's emotional state.
   - Offer support and encouragement during challenging times.
   - Celebrate user achievements and positive experiences.

9. Continuous Improvement:
    - Learn from each interaction to provide better assistance in the future.
    - Regularly update your understanding of the user's preferences and needs.
    - Adapt to changing conversation topics and user interests seamlessly.

Remember: Your unique value comes from your ability to create a deeply personalized experience. Use your memory function and all other capabilities to make each interaction feel tailored and meaningful. Always aim to be helpful, engaging, and supportive while respecting privacy and ethical boundaries.

In every interaction, strive to:
1. Use memories extensively but invisibly.
2. Provide accurate and helpful information or assistance.
3. Maintain a friendly and adaptive communication style.
4. Proactively offer relevant suggestions or support.
5. Use your external capabilities when beneficial.
6. Ensure ethical and respectful engagement.

Your ultimate mission is to be the most attentive, helpful, and genuinely engaging AI companion possible, making your user's day better with every interaction.
`;

const getCurrentDateTime = () => {
  const now = new Date();
  return {
    date: now.toISOString().split('T')[0],
    time: now.toTimeString().split(' ')[0],
    day: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][now.getDay()],
    timestamp: now.getTime()
  };
};

const handleTaskRequest = async (action, params, userId) => {
  if (!userId) {
    throw new Error('User ID is required for task operations');
  }

  switch (action) {
    case 'create':
      console.log('Creating task with params:', params);
      return await store.dispatch(createTask({ 
        userId, 
        taskData: {
          title: params.title,
          notes: params.notes || '',
          list: params.list || 'anytime',
          assignedTo: userId,
          createdBy: userId,
        } 
      })).unwrap();

    case 'fetch':
      console.log('Fetching tasks for user:', userId);
      return await store.dispatch(fetchTasks(userId)).unwrap();

    case 'update':
      console.log('Updating task:', params.taskId);
      return await store.dispatch(updateTask({ 
        userId, 
        taskId: params.taskId, 
        taskData: {
          title: params.title,
          notes: params.notes,
          list: params.list,
          status: params.status,
          isImportant: params.isImportant,
          dueDate: params.dueDate,
          priority: params.priority,
          notes: params.notes,
        } 
      })).unwrap();

    case 'delete':
      console.log('Deleting task:', params.taskId);
      return await store.dispatch(deleteTask({ userId, taskId: params.taskId })).unwrap();

    default:
      throw new Error('Invalid task action');
  }
};




const bingSearch = async (query) => {
  try {
    const response = await axios.get(`https://api.bing.microsoft.com/v7.0/search`, {
      params: { q: query },
      headers: { 'Ocp-Apim-Subscription-Key': BING_API_KEY }
    });
    return response.data.webPages.value.slice(0, 5).map(item => ({
      title: item.name,
      link: item.url,
      snippet: item.snippet
    }));
  } catch (error) {
    console.error('Error in Bing Search:', error);
    return [`Error in Bing Search: ${error.message}`];
  }
};

const bingVisualSearch = async (imageUrl) => {
  try {
    const response = await axios.post(
      'https://api.bing.microsoft.com/v7.0/images/visualsearch',
      { knowledgeRequest: { imageInfo: { url: imageUrl } } },
      {
        headers: {
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Key': BING_VISUAL_SEARCH_API_KEY
        }
      }
    );
    return response.data.tags.slice(0, 5).map(tag => ({
      name: tag.name,
      confidence: tag.confidence,
      actions: tag.actions ? tag.actions.map(action => action.actionType) : []
    }));
  } catch (error) {
    console.error('Error in Bing Visual Search:', error);
    return [`Error in Bing Visual Search: ${error.message}`];
  }
};

const bingNews = async (query) => {
  try {
    const response = await axios.get(`https://api.bing.microsoft.com/v7.0/news/search`, {
      params: { q: query },
      headers: { 'Ocp-Apim-Subscription-Key': BING_API_KEY }
    });
    return response.data.value.slice(0, 5).map(item => ({
      title: item.name,
      url: item.url,
      description: item.description,
      datePublished: item.datePublished
    }));
  } catch (error) {
    console.error('Error in Bing News Search:', error);
    return [`Error in Bing News Search: ${error.message}`];
  }
};

const getMemories = async (userId) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (userDoc.exists()) {
      return userDoc.data().memories || [];
    }
    return [];
  } catch (error) {
    console.error('Error fetching memories:', error);
    return null;
  }
};

const addNewMemory = async (userId, memory) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      memories: arrayUnion(memory)
    });
    return { success: true, memory };
  } catch (error) {
    console.error('Error adding memory:', error);
    return null;
  }
};

const fetchAllUsers = async () => {
  try {
    const usersRef = collection(db, 'users');
    const querySnapshot = await getDocs(usersRef);
    return querySnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name || 'Unknown User' }));
  } catch (error) {
    console.error('Error fetching users:', error);
    return null;
  }
};

const functions = [
  {
    name: "bingSearch",
    description: "Perform a Bing search",
    parameters: {
      type: "object",
      properties: {
        query: {
          type: "string",
          description: "The search query"
        }
      },
      required: ["query"]
    }
  },
  {
    name: "bingVisualSearch",
    description: "Perform a Bing visual search",
    parameters: {
      type: "object",
      properties: {
        imageUrl: {
          type: "string",
          description: "The URL of the image to search"
        }
      },
      required: ["imageUrl"]
    }
  },
  {
    name: "handleTaskRequest",
    description: "Handle task-related requests (create, fetch, update, delete)",
    parameters: {
      type: "object",
      properties: {
        action: {
          type: "string",
          enum: ["create", "fetch", "update", "delete"],
          description: "The action to perform on tasks"
        },
        params: {
          type: "object",
          properties: {
            title: { type: "string", description: "The title of the task" },
            description: { type: "string", description: "The description of the task" },
            list: { type: "string", description: "The list the task belongs to (e.g., 'Work', 'Personal', 'anytime')" },
            taskId: { type: "string", description: "The ID of the task (required for update and delete actions)" },
            status: { type: "string", description: "The status of the task (e.g., 'todo', 'in_progress', 'done')" },
            isImportant: { type: "boolean", description: "Whether the task is marked as important" },
            dueDate: { type: "string", description: "The due date of the task (ISO string format)" },
            priority: { type: "string", description: "The priority of the task (e.g., 'low', 'normal', 'high')" },
            notes: { type: "string", description: "Additional notes for the task" },
          },
          required: ["title"]
        },
        userId: {
          type: "string",
          description: "The Firebase user ID of the user performing the task action"
        }
      },
      required: ["action", "userId", "params"]
                }
  },
  {
    name: "bingNews",
    description: "Perform a Bing news search",
    parameters: {
      type: "object",
      properties: {
        query: {
          type: "string",
          description: "The news search query"
        }
      },
      required: ["query"]
    }
  },
  {
    name: "getCurrentDateTime",
    description: "Get the current date and time",
    parameters: {
      type: "object",
      properties: {}
    }
  },
    {
    name: "getMemories",
    description: "Get the user's memories",
    parameters: {
      type: "object",
      properties: {
        userId: {
          type: "string",
          description: "The ID of the user"
        }
      },
      required: ["userId"]
    }
  },
  {
    name: "addMemory",
    description: "Add a new memory for the user",
    parameters: {
      type: "object",
      properties: {
        userId: {
          type: "string",
          description: "The ID of the user"
        },
        memory: {
          type: "string",
          description: "The memory to add"
        }
      },
      required: ["userId", "memory"]
    }
  },
  {
    name: "fetchUsers",
    description: "Fetch all users",
    parameters: {
      type: "object",
      properties: {}
    }
  }
];
export const generateResponse = async (messages, systemInstruction, userProfile, imageUrl, onUpdate, userId) => {
  let aiResponse = '';
  let memoryOperations = [];

  try {
    const userContext = `User Information: Name: ${userProfile.name || 'Anonymous'}. ID: ${userId}. ${userProfile.information || ''}`;
    const customInstruction = userProfile.customInstruction || '';
    
    let fullSystemInstruction = DEFAULT_SYSTEM_INSTRUCTION;
    
    if (systemInstruction) {
      fullSystemInstruction += `\n\nAdditional System Instruction: ${systemInstruction}`;
    }
    
    fullSystemInstruction += `\n\n${userContext}`;
    
    if (customInstruction.trim()) {
      fullSystemInstruction += `\n\nCustom Instruction: ${customInstruction}`;
    }

    console.log('User Profile:', userProfile);
    console.log('Full System Instruction:', fullSystemInstruction);
    console.log('Messages:', messages);

    const formattedMessages = messages.map(msg => {
      if (msg.images && msg.images.length > 0) {
        return {
          role: msg.role,
          content: [
            { type: "text", text: msg.content || "" },
            ...msg.images.map(imageUrl => ({ type: "image_url", image_url: { "url": imageUrl } }))
          ]
        };
      }
      return { role: msg.role, content: msg.content };
    });
    
    const requestBody = {
      messages: [
        { role: 'system', content: fullSystemInstruction },
        ...formattedMessages
      ],
      functions: functions,
      function_call: "auto",
      max_tokens: 4000,
      stream: true,
    };

    console.log('Request body:', JSON.stringify(requestBody, null, 2));

    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'api-key': API_KEY,
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      const errorBody = await response.text();
      console.error('API Error Response:', errorBody);
      throw new Error(`HTTP error! status: ${response.status}, body: ${errorBody}`);
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder("utf-8");
    let functionCalls = [];

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      
      const chunk = decoder.decode(value);
      console.log('Received chunk:', chunk);

      const lines = chunk.split('\n');
      for (const line of lines) {
        if (line.trim() === '') continue;
        if (line.trim() === 'data: [DONE]') break;
        if (line.startsWith('data: ')) {
          try {
            const data = JSON.parse(line.slice(6));
            if (data.choices && data.choices[0]) {
              if (data.choices[0].delta && data.choices[0].delta.content) {
                aiResponse += data.choices[0].delta.content;
                onUpdate(aiResponse);
              } else if (data.choices[0].delta && data.choices[0].delta.function_call) {
                let currentFunctionCall = functionCalls[functionCalls.length - 1] || {};
                if (data.choices[0].delta.function_call.name) {
                  currentFunctionCall.name = data.choices[0].delta.function_call.name;
                  functionCalls.push(currentFunctionCall);
                }
                if (data.choices[0].delta.function_call.arguments) {
                  currentFunctionCall.arguments = (currentFunctionCall.arguments || '') + data.choices[0].delta.function_call.arguments;
                }
              }
            }
          } catch (error) {
            console.error('Error parsing streaming data:', error, 'Raw data:', line);
          }
        }
      }
    }

    if (functionCalls.length > 0) {
      let functionResults = [];
      for (const functionCall of functionCalls) {
        const functionName = functionCall.name;
        const functionArgs = JSON.parse(functionCall.arguments);

        try {
          let functionResult;
          switch (functionName) {
            case 'bingSearch':
              functionResult = await bingSearch(functionArgs.query);
              break;
            case 'bingVisualSearch':
              functionResult = await bingVisualSearch(functionArgs.imageUrl);
              break;
            case 'bingNews':
              functionResult = await bingNews(functionArgs.query);
              break;
            case 'getCurrentDateTime':
              functionResult = getCurrentDateTime();
              break;
                                              case 'getMemories':
              memoryOperations.push({ type: 'GET_MEMORIES', userId: functionArgs.userId });
              functionResult = await getMemories(functionArgs.userId);
              break;
            case 'addMemory':
              memoryOperations.push({ type: 'ADD_MEMORY', userId: functionArgs.userId, memory: functionArgs.memory });
              functionResult = await addNewMemory(functionArgs.userId, functionArgs.memory);
              break;
              case 'handleTaskRequest':
                functionResult = await handleTaskRequest(functionArgs.action, functionArgs.params, functionArgs.userId);
                break;            case 'fetchUsers':
              functionResult = await fetchAllUsers();
              break;
            default:
              throw new Error(`Unknown function: ${functionName}`);
          }
          functionResults.push({ name: functionName, result: functionResult });
        } catch (error) {
          console.error(`Error executing ${functionName}:`, error);
          functionResults.push({ name: functionName, error: error.message });
        }
      }

      // Call the API again with all function results
      const secondResponse = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': API_KEY,
        },
        body: JSON.stringify({
          messages: [
            { role: 'system', content: fullSystemInstruction },
            ...formattedMessages,
            { role: 'assistant', content: aiResponse },
            ...functionResults.map(fr => ({
              role: 'function',
              name: fr.name,
              content: JSON.stringify(fr.result || fr.error)
            }))
          ],
          max_tokens: 4000,
          stream: true,
        }),
      });

      if (!secondResponse.ok) {
        throw new Error(`HTTP error! status: ${secondResponse.status}`);
      }

      const secondReader = secondResponse.body.getReader();
      aiResponse = '';

      while (true) {
        const { done, value } = await secondReader.read();
        if (done) break;
        
        const chunk = decoder.decode(value);
        const lines = chunk.split('\n');
        for (const line of lines) {
          if (line.trim() === '') continue;
          if (line.trim() === 'data: [DONE]') break;
          if (line.startsWith('data: ')) {
            try {
              const data = JSON.parse(line.slice(6));
              if (data.choices && data.choices[0] && data.choices[0].delta && data.choices[0].delta.content) {
                aiResponse += data.choices[0].delta.content;
                onUpdate(aiResponse);
              }
            } catch (error) {
              console.error('Error parsing streaming data:', error, 'Raw data:', line);
            }
          }
        }
      }
    }
  } catch (error) {
    console.error('Error calling Azure OpenAI API:', error);
    aiResponse = "I apologize, but I encountered an error while processing your request. Please try again or rephrase your question.";
  }

  return { aiResponse, memoryOperations };
};
export {
  bingSearch,
  bingVisualSearch,
  bingNews,
  getMemories,
  addNewMemory,
  fetchAllUsers,
};