import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllUsers } from './redux/userSlice';
import { fetchAgents, shareAgent } from './redux/chatSlice';
import { fetchTasks, updateTask } from './redux/taskSlice';
import { XIcon } from '@heroicons/react/outline';

const ADMIN_ID = '2EONAtrLQIbr3VYY5JSTzOvgcso2';

const AdminPanel = ({ onClose }) => {
  const dispatch = useDispatch();
  const users = useSelector(state => state.user.users);
  const agents = useSelector(state => state.chat.agents);
  const tasks = useSelector(state => state.tasks.tasks);

  const [selectedAgent, setSelectedAgent] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedTask, setSelectedTask] = useState('');

  useEffect(() => {
    dispatch(fetchAllUsers());
    dispatch(fetchAgents());
    dispatch(fetchTasks(ADMIN_ID));
  }, [dispatch]);

  const handleShareAgent = () => {
    if (selectedAgent && selectedUser) {
      dispatch(shareAgent({ agentId: selectedAgent, userId: selectedUser })).then((action) => {
        if (action.error) {
          console.error('Failed to share agent', action.error.message);
        } else {
          console.log('Agent shared successfully:', action.payload);
          setSelectedAgent('');
          setSelectedUser('');
        }
      });
    }
  };

  const handleAssignTask = () => {
    if (selectedTask && selectedUser) {
      dispatch(updateTask({
        userId: ADMIN_ID,
        taskId: selectedTask,
        taskData: { assignedTo: selectedUser }
      })).then((action) => {
        if (action.error) {
          console.error('Failed to assign task', action.error.message);
        } else {
          console.log('Task assigned successfully:', action.payload);
        }
      });
    } else {
      console.log('Select both task and user before assigning');
    }
  };

  const handleAddCollaborator = () => {
    if (selectedTask && selectedUser) {
      const task = tasks.find(t => t.id === selectedTask);
      if (task) {
        const collaborators = task.collaborators || [];
        if (!collaborators.includes(selectedUser)) {
          dispatch(updateTask({
            userId: ADMIN_ID,
            taskId: selectedTask,
            taskData: { collaborators: [...collaborators, selectedUser] }
          }));
        }
      }
    }
  };

  const getUserLabel = (user) => {
    return user.email && user.email !== 'No email' ? `${user.email} (${user.uid})` : user.uid;
  };

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-gray-800 text-white p-6 rounded-lg w-full max-w-3xl relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-white"
        >
          <XIcon className="h-6 w-6" />
        </button>
        <h2 className="text-2xl font-bold mb-6">Admin Panel</h2>

        <div className="mb-8">
          <h3 className="text-xl font-semibold mb-4">Share AI Agent</h3>
          <select
            value={selectedAgent}
            onChange={(e) => setSelectedAgent(e.target.value)}
            className="bg-gray-700 text-white p-2 rounded mr-4"
          >
            <option value="">Select an AI Agent</option>
            {agents.map(agent => (
              <option key={agent.id} value={agent.id}>{agent.title}</option>
            ))}
          </select>
          <select
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value)}
            className="bg-gray-700 text-white p-2 rounded mr-4"
          >
            <option value="">Select a User</option>
            {users.map(user => (
              <option key={user.uid} value={user.uid}>{getUserLabel(user)}</option>
            ))}
          </select>
          <button
            onClick={handleShareAgent}
            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
          >
            Share Agent
          </button>
        </div>

        <div className="mb-8">
          <h3 className="text-xl font-semibold mb-4">Assign Task</h3>
          <select
            value={selectedTask}
            onChange={(e) => setSelectedTask(e.target.value)}
            className="bg-gray-700 text-white p-2 rounded mr-4"
          >
            <option value="">Select a Task</option>
            {tasks.map(task => (
              <option key={task.id} value={task.id}>{task.title}</option>
            ))}
          </select>
          <select
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value)}
            className="bg-gray-700 text-white p-2 rounded mr-4"
          >
            <option value="">Select a User</option>
            {users.map(user => (
              <option key={user.uid} value={user.uid}>{getUserLabel(user)}</option>
            ))}
          </select>
          <button
            onClick={handleAssignTask}
            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
          >
            Assign Task
          </button>
        </div>

        <div className="mb-8">
          <h3 className="text-xl font-semibold mb-4">Add Task Collaborator</h3>
          <select
            value={selectedTask}
            onChange={(e) => setSelectedTask(e.target.value)}
            className="bg-gray-700 text-white p-2 rounded mr-4"
          >
            <option value="">Select a Task</option>
            {tasks.map(task => (
              <option key={task.id} value={task.id}>{task.title}</option>
            ))}
          </select>
          <select
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value)}
            className="bg-gray-700 text-white p-2 rounded mr-4"
          >
            <option value="">Select a User</option>
            {users.map(user => (
              <option key={user.uid} value={user.uid}>{getUserLabel(user)}</option>
            ))}
          </select>
          <button
            onClick={handleAddCollaborator}
            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
          >
            Add Collaborator
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;