import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTasks, createTask, updateTask, deleteTask, updateDailyNotes, addTaskAttachment, removeTaskAttachment, createSubtask } from './redux/taskSlice';
import { fetchAllUsers } from './redux/userSlice';
import {
  PlusIcon, XIcon, CheckIcon, CalendarIcon, StarIcon, TrashIcon,
  EyeIcon, MenuIcon, PencilIcon, PaperClipIcon, MenuAlt2Icon, UserIcon
} from '@heroicons/react/outline';
import { auth } from './firebase';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import BackgroundDesign from './BackgroundDesign';

const TaskManagementPopup = ({ isOpen, onClose, currentDesign, cycleDesign }) => {
  const dispatch = useDispatch();
  const tasks = useSelector(state => [...state.tasks.tasks, ...state.tasks.collaboratorTasks]);
  const status = useSelector(state => state.tasks.status);
  const error = useSelector(state => state.tasks.error);
  const users = useSelector(state => state.user.users);
  const isAdmin = useSelector(state => state.user.isAdmin);

  const [newTaskTitle, setNewTaskTitle] = useState('');
  const [activeList, setActiveList] = useState('today');
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [customCategories, setCustomCategories] = useState([]);
  const [showFullNotes, setShowFullNotes] = useState(false);
  const [editedNotes, setEditedNotes] = useState('');
  const [showSidebar, setShowSidebar] = useState(false);
  const [showDailyNotes, setShowDailyNotes] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dailyNotes, setDailyNotes] = useState('');
  const [showAttachments, setShowAttachments] = useState(false);
  const [newSubtaskTitle, setNewSubtaskTitle] = useState('');
  const [showSubtasks, setShowSubtasks] = useState(false);
  const [taskWithSubtasks, setTaskWithSubtasks] = useState(null);
  const [showAssignUser, setShowAssignUser] = useState(false);
  const [editingTask, setEditingTask] = useState(null);

  const firebaseUser = auth.currentUser;

  useEffect(() => {
    console.log('All tasks:', tasks);
    tasks.forEach(task => {
      if (task.subtasks && task.subtasks.length > 0) {
        console.log(`Subtasks for task ${task.id}:`, task.subtasks);
      }
    });
  }, [tasks]);

  useEffect(() => {
    if (firebaseUser) {
      dispatch(fetchTasks(firebaseUser.uid))
        .unwrap()
        .then(() => console.log('Tasks fetched successfully'))
        .catch((error) => console.error('Failed to fetch tasks:', error));
      if (isAdmin) {
        dispatch(fetchAllUsers());
      }
    }
  }, [dispatch, firebaseUser, isAdmin]);

  useEffect(() => {
    if (isOpen && firebaseUser) {
      dispatch(fetchTasks(firebaseUser.uid))
        .unwrap()
        .then(() => console.log('Tasks fetched successfully'))
        .catch((error) => console.error('Failed to fetch tasks:', error));
      setCustomCategories([]);
    }
  }, [isOpen, firebaseUser, dispatch]);

  const handleCreateTask = async (e) => {
    e.preventDefault();
    if (!firebaseUser || !newTaskTitle.trim()) return;

    const taskData = {
      title: newTaskTitle,
      description: '',
      assignedTo: firebaseUser.uid,
      status: 'todo',
      list: activeList,
      createdAt: new Date().toISOString(),
      isImportant: false,
      dueDate: activeList === 'today' ? new Date().toISOString() : null,
      tags: [],
      priority: 'normal',
      notes: '',
      subtasks: [],
      collaborators: [firebaseUser.uid]
    };

    try {
      if (editingTask) {
        await dispatch(updateTask({ 
          firebaseUserId: firebaseUser.uid, 
          taskId: editingTask.id, 
          taskData: { title: newTaskTitle },
          taskCreatorId: editingTask.createdBy || editingTask.taskCreatorId
        })).unwrap();
        setEditingTask(null);
      } else {
        await dispatch(createTask({ userId: firebaseUser.uid, taskData })).unwrap();
      }
      console.log('Task created/updated successfully');
      setNewTaskTitle('');
      dispatch(fetchTasks(firebaseUser.uid));
    } catch (error) {
      console.error('Failed to create/update task:', error);
    }
  };

  const handleCreateSubtask = async (e, taskId) => {
    e.preventDefault();
    if (!firebaseUser || !newSubtaskTitle.trim()) return;
  
    const subtaskData = {
      title: newSubtaskTitle,
      notes: '',
      dailyNotes: {}
    };
  
    try {
      const task = tasks.find(task => task.id === taskId);
      if (!task) return;
  
      await dispatch(
        createSubtask({
          userId: firebaseUser.uid,
          taskId,
          subtaskData,
          taskCreatorId: task.taskCreatorId
        })
      ).unwrap();
  
      console.log('Subtask created successfully');
      setNewSubtaskTitle('');
    } catch (error) {
      console.error('Failed to create subtask:', error);
    }
  };
  
  const handleToggleComplete = useCallback((taskId, completed, taskCreatorId) => {
    if (firebaseUser) {
      console.log('Toggle Complete:', { taskId, completed, taskCreatorId, firebaseUserId: firebaseUser.uid });
      dispatch(updateTask({ 
        firebaseUserId: firebaseUser.uid, 
        taskId, 
        taskData: { status: completed ? 'todo' : 'done' },
        taskCreatorId 
      }))
      .unwrap()
      .then(() => {
        console.log('Task status updated successfully');
        dispatch(fetchTasks(firebaseUser.uid));
      })
      .catch((error) => {
        console.error('Failed to update task status:', error);
      });
    }
  }, [dispatch, firebaseUser]);
  
  const handleToggleImportant = useCallback((taskId, isImportant, taskCreatorId) => {
    if (firebaseUser) {
      dispatch(updateTask({ 
        userId: firebaseUser.uid, 
        taskId, 
        taskData: { isImportant: !isImportant },
        taskCreatorId 
      }));
    }
  }, [dispatch, firebaseUser]);

  const handleDateChange = useCallback((taskId, date, taskCreatorId) => {
    if (firebaseUser) {
      console.log('Changing date for task:', { taskId, date, taskCreatorId });
      dispatch(updateTask({ 
        firebaseUserId: firebaseUser.uid, 
        taskId, 
        taskData: { dueDate: date ? date.toISOString() : null },
        taskCreatorId 
      }))
      .unwrap()
      .then(() => {
        console.log('Task date updated successfully');
        return dispatch(fetchTasks(firebaseUser.uid)).unwrap();
      })
      .then(() => {
        console.log('Tasks fetched successfully after date update');
        setShowDatePicker(false);
      })
      .catch((error) => {
        console.error('Error updating task date or fetching tasks:', error);
      });
    }
  }, [dispatch, firebaseUser]);

  const handleDeleteTask = useCallback((taskId, taskCreatorId) => {
    if (firebaseUser) {
      dispatch(deleteTask({ userId: firebaseUser.uid, taskId, taskCreatorId }));
    }
  }, [dispatch, firebaseUser]);

  const handleCreateCategory = () => {
    if (newCategoryName.trim()) {
      const newCategory = {
        id: newCategoryName.toLowerCase().replace(/\s+/g, '-'),
        name: newCategoryName,
        icon: '📁'
      };
      setCustomCategories([...customCategories, newCategory]);
      setNewCategoryName('');
    }
  };

  const handleNotesChange = useCallback((task, newNotes) => {
    setEditedNotes(newNotes);
    setSelectedTask({ ...task, notes: newNotes });
    if (firebaseUser) {
      dispatch(updateTask({ 
        userId: firebaseUser.uid, 
        taskId: task.id, 
        taskData: { notes: newNotes },
        taskCreatorId: task.taskCreatorId 
      }));
    }
  }, [dispatch, firebaseUser]);

  const handleDailyNotesChange = useCallback((task, date, notes) => {
    if (firebaseUser) {
      const dateString = date.toISOString().split('T')[0];
      dispatch(updateDailyNotes({ 
        userId: firebaseUser.uid, 
        taskId: task.id, 
        date: dateString, 
        notes,
        taskCreatorId: task.taskCreatorId 
      }));
    }
  }, [dispatch, firebaseUser]);

  const handleFileUpload = useCallback((taskId, file, taskCreatorId) => {
    if (firebaseUser) {
      dispatch(addTaskAttachment({ userId: firebaseUser.uid, taskId, file, taskCreatorId }));
    }
  }, [dispatch, firebaseUser]);

  const handleRemoveAttachment = useCallback((taskId, attachment, taskCreatorId) => {
    if (firebaseUser) {
      dispatch(removeTaskAttachment({ userId: firebaseUser.uid, taskId, attachment, taskCreatorId }));
    }
  }, [dispatch, firebaseUser]);

  const handleSubtaskNotesChange = (task, subtaskIndex, newNotes) => {
    if (!firebaseUser) return;

    const updatedSubtasks = task.subtasks.map((subtask, index) =>
      index === subtaskIndex ? { ...subtask, notes: newNotes } : subtask
    );

    const updatedTask = { ...task, subtasks: updatedSubtasks };
    setSelectedTask(updatedTask);

    dispatch(updateTask({
      userId: firebaseUser.uid,
      taskId: task.id,
      taskData: { subtasks: updatedSubtasks },
      taskCreatorId: task.taskCreatorId
    }));
  };

  const handleToggleSubtasks = (task) => {
    if (taskWithSubtasks?.id === task.id) {
      setTaskWithSubtasks(null);
    } else {
      setTaskWithSubtasks(task);
    }
  };

  const handleAssignTask = (taskId, userId) => {
    if (firebaseUser && (isAdmin || selectedTask.createdBy === firebaseUser.uid)) {
      const updatedCollaborators = [...new Set([...(selectedTask.collaborators || []), userId])];
      dispatch(updateTask({ 
        firebaseUserId: firebaseUser.uid,
        taskId, 
        taskData: { 
          assignedTo: userId,
          collaborators: updatedCollaborators
        },
        taskCreatorId: selectedTask.createdBy || selectedTask.taskCreatorId
      }))
      .unwrap()
      .then(() => {
        dispatch(fetchTasks(firebaseUser.uid));
        setShowAssignUser(false);
      })
      .catch((error) => {
        console.error('Failed to assign task:', error);
      });
    }
  };

  const getAssignedUserInfo = (userId) => {
    const user = users.find(u => u.uid === userId);
    return user ? `${user.email} (${user.uid})` : 'Unassigned';
  };

  const handleEditTask = (task) => {
    setEditingTask(task);
    setNewTaskTitle(task.title);
  };

  const filteredTasks = useMemo(() => {
    const uniqueTasks = {};
    tasks.forEach(task => {
      if (
        (activeList === 'today' && task.dueDate && new Date(task.dueDate).toDateString() === new Date().toDateString()) ||
        (activeList === 'upcoming' && task.dueDate && new Date(task.dueDate) > new Date() && new Date(task.dueDate) <= new Date(new Date().setDate(new Date().getDate() + 7))) ||
        task.list === activeList
      ) {
        uniqueTasks[task.id] = {
          ...task,
          subtasks: task.subtasks || []
        };
      }
    });
    return Object.values(uniqueTasks);
  }, [tasks, activeList]);

  const lists = [
    { id: 'today', name: 'Today', icon: '📅' },
    { id: 'upcoming', name: 'Upcoming', icon: '🗓️' },
    { id: 'anytime', name: 'Anytime', icon: '🔆' },
    { id: 'someday', name: 'Someday', icon: '💭' },
    { id: 'everyday', name: 'Everyday', icon: '🔁' },
    ...customCategories
  ];

  const activeListItem = lists.find(l => l.id === activeList) || lists[0];

  const truncateNotes = (notes, maxLength = 50) => {
    if (notes?.length <= maxLength) return notes;
    return notes?.substr(0, maxLength - 3) + '...';
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'No due date';
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center z-50">
      <div className="bg-gray-900 w-full h-full flex text-gray-200 relative overflow-hidden min-h-screen">
        <BackgroundDesign design={currentDesign} />
        <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm"></div>

        {/* Sidebar */}
        <div className={`fixed inset-y-0 left-0 z-50 transform ${showSidebar ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 md:relative md:translate-x-0 md:w-64 bg-gray-800 bg-opacity-75 p-4 flex flex-col z-10`}>
          <button 
            onClick={() => setShowSidebar(false)}
            className="text-gray-400 hover:text-white mb-4 md:hidden"
          >
            <XIcon className="h-6 w-6" />
          </button>

          {lists.map(list => (
            <button
              key={list.id}
              onClick={() => {
                setActiveList(list.id);
                setShowSidebar(false);
              }}
              className={`flex items-center p-2 rounded-lg mb-2 ${activeList === list.id ? 'bg-blue-600 text-white' : 'text-gray-300 hover:bg-gray-700'}`}
            >
              <span className="mr-2">{list.icon}</span>
              {list.name}
            </button>
          ))}
          <div className="mt-4">
            <input
              type="text"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              placeholder="New category name"
              className="w-full p-2 bg-gray-700 rounded"
            />
            <button
              onClick={handleCreateCategory}
              className="mt-2 w-full bg-blue-600 text-white p-2 rounded hover:bg-blue-700"
            >
              Add Category
            </button>
          </div>
          <button onClick={onClose} className="mt-auto text-gray-400 hover:text-white">
            Close
          </button>
        </div>

        {/* Main Content */}
        <div className="flex-1 flex flex-col relative z-10 overflow-hidden h-full">
          {/* Close Button */}
          <button
            onClick={onClose}
            className="absolute top-4 right-4 z-20 text-gray-400 hover:text-white"
          >
            <XIcon className="h-6 w-6" />
          </button>
          <button
            onClick={() => setShowSidebar(true)}
            className="absolute top-4 left-4 z-20 text-gray-400 hover:text-white md:hidden"
          >
            <MenuAlt2Icon className="h-6 w-6" />
          </button>
          <div className="flex-1 overflow-y-auto p-4 h-full">
            {error && <p className="text-red-400">Failed to load tasks. Please try again.</p>}
            {!error && filteredTasks.length === 0 && (
              <p className="text-gray-400">No tasks in this list. Add a task to get started!</p>
            )}
            {!error && filteredTasks.map(task => (
              <div key={task.id} className="mb-4 p-4 bg-gray-800 rounded-lg">
                <div className="flex items-center justify-between mb-2">
                  <span className={`flex-1 ${task.status === 'done' ? 'line-through text-gray-500' : ''}`}>
                    {task.title}
                  </span>
                  <span className="text-sm text-gray-400 ml-2">
                    Due: {formatDate(task.dueDate)}
                  </span>
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => handleToggleComplete(task.id, task.status === 'done', task.createdBy || task.taskCreatorId)}
                      className={`w-5 h-5 rounded-full border-2 flex items-center justify-center
                        ${task.status === 'done' ? 'bg-green-500 border-green-500' : 'border-gray-400'}`}
                    >
                      {task.status === 'done' && <CheckIcon className="w-3 h-3 text-white" />}
                    </button>
                    <button
                      onClick={() => {
                        setSelectedTask(task);
                        setShowDatePicker(true);
                      }}
                      className="text-gray-400 hover:text-white"
                    >
                      <CalendarIcon className="h-4 w-4" />
                    </button>
                    <button
                      onClick={() => handleToggleImportant(task.id, task.isImportant)}
                      className={`${task.isImportant ? 'text-yellow-500' : 'text-gray-400'} hover:text-yellow-500`}
                    >
                      <StarIcon className="h-4 w-4" />
                    </button>
                    {(isAdmin || task.createdBy === firebaseUser.uid) && (
                      <button
                        onClick={() => {
                          setSelectedTask(task);
                          setShowAssignUser(true);
                        }}
                        className="text-gray-400 hover:text-white"
                        title="Assign Task"
                      >
                        <UserIcon className="h-4 w-4" />
                      </button>
                    )}
                    <button
                      onClick={() => handleEditTask(task)}
                      className="text-blue-400 hover:text-blue-300"
                    >
                      <PencilIcon className="h-4 w-4" />
                    </button>
                    <button
                      onClick={() => handleDeleteTask(task.id)}
                      className="text-gray-400 hover:text-red-500"
                    >
                      <TrashIcon className="h-4 w-4" />
                    </button>
                  </div>
                </div>
                <div className="mt-2 text-sm text-gray-400 flex items-center justify-between">
                  <span>{truncateNotes(task.notes)}</span>
                  <div className="flex space-x-2">
                    <button
                      onClick={() => {
                        setSelectedTask(task);
                        setShowFullNotes(true);
                        setEditedNotes(task.notes ?? "");
                      }}
                      className="text-blue-400 hover:text-blue-300"
                    >
                      <EyeIcon className="h-4 w-4" />
                    </button>
                    {task.list === 'everyday' && (
                      <button
                        onClick={() => {
                          setSelectedTask(task);
                          setShowDailyNotes(true);
                          setSelectedDate(new Date());
                          setDailyNotes(task.dailyNotes?.[new Date().toISOString().split('T')[0]] ?? "");
                        }}
                        className="text-green-400 hover:text-green-300"
                      >
                        <PencilIcon className="h-4 w-4" />
                      </button>
                    )}
                    <button
                      onClick={() => {
                        setSelectedTask(task);
                        handleToggleSubtasks(task);
                      }}
                      className="text-indigo-400 hover:text-indigo-300"
                    >
                      <MenuIcon className="h-4 w-4" />
                    </button>
                  </div>
                </div>
                <div className="mt-2 text-xs text-gray-500">
                  Assigned to: {getAssignedUserInfo(task.assignedTo)}
                </div>
                {taskWithSubtasks?.id === task.id && (
                  <div className="mt-4">
                    <form onSubmit={(e) => handleCreateSubtask(e, task.id)} className="flex items-center">
                      <PlusIcon className="h-5 w-5 text-indigo-500 mr-2" />
                      <input
                        type="text"
                        value={newSubtaskTitle}
                        onChange={(e) => setNewSubtaskTitle(e.target.value)}
                        placeholder="Add a subtask..."
                        className="flex-1 bg-transparent border-none focus:outline-none text-white placeholder-gray-500"
                      />
                      <button type="submit" className="ml-2 bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700">
                        Add
                      </button>
                    </form>
                    {task.subtasks && task.subtasks.map((subtask, index) => (
                      <div key={`${task.id}-subtask-${index}`} className="mt-2 pl-6 flex items-center justify-between">
                        <span className="text-sm text-gray-400">{subtask.title}</span>
                        <div className="flex space-x-2">
                          <button
                            onClick={() => setSelectedTask({ ...task, selectedSubtaskIndex: index })}
                            className="text-blue-400 hover:text-blue-300"
                          >
                            <PencilIcon className="h-4 w-4" />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* Date Picker Modal */}
          {showDatePicker && selectedTask && (
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-gray-800 p-4 rounded-lg">
              <DatePicker
                selected={selectedTask.dueDate ? new Date(selectedTask.dueDate) : null}
                onChange={(date) => handleDateChange(selectedTask.id, date)}
                inline
              />
              <button
                onClick={() => setShowDatePicker(false)}
                className="mt-2 w-full bg-blue-600 text-white p-2 rounded hover:bg-blue-700"
              >
                Close
              </button>
            </div>
          )}

          {/* Full Notes Modal */}
          {showFullNotes && selectedTask && (
            <div className="absolute inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center">
              <div className="bg-gray-800 p-6 rounded-lg w-full max-w-md">
                <h3 className="text-xl font-bold mb-4">{selectedTask.title} - Notes</h3>
                <textarea
                  value={editedNotes}
                  onChange={(e) => setEditedNotes(e.target.value)}
                  onBlur={() => handleNotesChange(selectedTask, editedNotes)}
                  className="w-full bg-gray-700 text-white px-3 py-2 rounded mb-4"
                  placeholder="Task notes"
                  rows="6"
                />
                <div className="flex justify-end">
                  <button
                    onClick={() => setShowFullNotes(false)}
                    className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Daily Notes Modal */}
          {showDailyNotes && selectedTask && (
            <div className="absolute inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center">
              <div className="bg-gray-800 p-6 rounded-lg w-full max-w-md">
                <h3 className="text-xl font-bold mb-4">{selectedTask.title} - Daily Notes</h3>
                <div className="mb-4">
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => {
                      setSelectedDate(date);
                      setDailyNotes(selectedTask.dailyNotes?.[date.toISOString().split('T')[0]] ?? "");
                    }}
                    className="w-full bg-gray-700 text-white px-3 py-2 rounded"
                  />
                </div>
                <textarea
                  value={dailyNotes}
                  onChange={(e) => setDailyNotes(e.target.value)}
                  onBlur={() => handleDailyNotesChange(selectedTask, selectedDate, dailyNotes)}
                  className="w-full bg-gray-700 text-white px-3 py-2 rounded mb-4"
                  placeholder="Enter your daily notes here..."
                  rows="6"
                />
                <div className="flex justify-end">
                  <button
                    onClick={() => setShowDailyNotes(false)}
                    className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Assign User Modal */}
          {showAssignUser && selectedTask && (
            <div className="absolute inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center">
              <div className="bg-gray-800 p-6 rounded-lg w-full max-w-md">
                <h3 className="text-xl font-bold mb-4">Assign Task: {selectedTask.title}</h3>
                <div className="space-y-2 max-h-60 overflow-y-auto">
                  {users.map(user => (
                    <button
                      key={user.uid}
                      onClick={() => handleAssignTask(selectedTask.id, user.uid)}
                      className="w-full text-left px-4 py-2 bg-gray-700 hover:bg-gray-600 rounded"
                    >
                      {user.email} ({user.uid})
                    </button>
                  ))}
                </div>
                <div className="flex justify-end mt-4">
                  <button
                    onClick={() => setShowAssignUser(false)}
                    className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Add/Edit Task Form */}
          <form onSubmit={handleCreateTask} className="p-4 border-t border-gray-700 flex items-center relative z-10">
            <PlusIcon className="h-5 w-5 text-blue-500 mr-2" />
            <input
              type="text"
              value={newTaskTitle}
              onChange={(e) => setNewTaskTitle(e.target.value)}
              placeholder={editingTask ? "Edit task..." : "Add a to-do..."}
              className="flex-1 bg-transparent border-none focus:outline-none text-white placeholder-gray-500"
            />
            <button type="submit" className="ml-2 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700">
              {editingTask ? 'Update' : 'Add'}
            </button>
            {editingTask && (
              <button 
                type="button" 
                onClick={() => {
                  setEditingTask(null);
                  setNewTaskTitle('');
                }} 
                className="ml-2 bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700"
              >
                Cancel
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default TaskManagementPopup;