import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchMemories, updateMemory, deleteMemory, deleteAllMemories } from './redux/memoriesSlice';
import { XIcon, PencilIcon, TrashIcon } from '@heroicons/react/solid';

const MemoriesManager = ({ onClose, userId }) => {
  const dispatch = useDispatch();
  const memories = useSelector(state => state.memories.items);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedMemory, setEditedMemory] = useState('');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  useEffect(() => {
    dispatch(fetchMemories(userId));
  }, [dispatch, userId]);

  const handleEdit = (index, memory) => {
    setEditingIndex(index);
    setEditedMemory(memory);
  };

  const handleSave = async (index) => {
    await dispatch(updateMemory({ userId, index, updatedMemory: editedMemory }));
    setEditingIndex(null);
  };

  const handleDelete = async (index) => {
    await dispatch(deleteMemory({ userId, index }));
  };

  const confirmDeleteAll = async () => {
    await dispatch(deleteAllMemories(userId));
    setShowDeleteConfirmation(false);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 p-6 rounded-lg max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold text-white">Manage Memories</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-white">
            <XIcon className="h-6 w-6" />
          </button>
        </div>
        <div className="space-y-4">
          {memories.map((memory, index) => (
            <div key={index} className="bg-gray-700 p-4 rounded-lg">
              {editingIndex === index ? (
                <div>
                  <textarea
                    value={editedMemory}
                    onChange={(e) => setEditedMemory(e.target.value)}
                    className="w-full bg-gray-600 text-white p-2 rounded mb-2"
                    rows="3"
                  />
                  <div className="flex">
                    <button
                      onClick={() => handleSave(index)}
                      className="bg-blue-500 text-white px-3 py-2 rounded mr-2"
                    >
                      Save
                    </button>
                    <button
                      onClick={() => setEditingIndex(null)}
                      className="bg-gray-500 text-white px-3 py-2 rounded"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex justify-between items-start">
                  <p className="text-white">{memory}</p>
                  <div className="flex">
                    <button
                      onClick={() => handleEdit(index, memory)}
                      className="text-blue-400 hover:text-blue-300 mr-2"
                    >
                      <PencilIcon className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleDelete(index)}
                      className="text-red-400 hover:text-red-300"
                    >
                      <TrashIcon className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        {memories.length > 0 && (
          <div className="mt-4">
            <button
              onClick={() => setShowDeleteConfirmation(true)}
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 w-full"
            >
              Delete All Memories
            </button>
          </div>
        )}
        {showDeleteConfirmation && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-60">
            <div className="bg-gray-800 p-6 rounded-lg max-w-sm w-full">
              <h3 className="text-xl font-bold text-white mb-4">Delete All Memories</h3>
              <p className="text-white mb-6">Are you sure you want to delete all memories? This action cannot be undone.</p>
              <div className="flex justify-end space-x-4">
                <button
                  onClick={() => setShowDeleteConfirmation(false)}
                  className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                >
                  Cancel
                </button>
                <button
                  onClick={confirmDeleteAll}
                  className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                >
                  Delete All
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MemoriesManager;
