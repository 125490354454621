// AgentEditModal.js
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateAgent } from './redux/chatSlice';

function AgentEditModal({ agent, onClose, userId }) {
  const dispatch = useDispatch();
  const [title, setTitle] = useState(agent.title);
  const [description, setDescription] = useState(agent.description || '');
  const [systemInstruction, setSystemInstruction] = useState(agent.systemInstruction || '');

  const handleSubmit = async (e) => {
    e.preventDefault();
    await dispatch(updateAgent({ 
      userId, 
      agentId: agent.id, 
      agentData: { title, description, systemInstruction } 
    }));
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-gray-800 p-6 rounded-lg w-full max-w-md">
        <h2 className="text-xl font-bold text-white mb-4">Edit AI Agent</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-300 mb-2" htmlFor="title">Title:</label>
            <input
              id="title"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full bg-gray-700 text-white p-2 rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-300 mb-2" htmlFor="description">Description:</label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full bg-gray-700 text-white p-2 rounded"
              rows="3"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-300 mb-2" htmlFor="systemInstruction">System Instruction:</label>
            <textarea
              id="systemInstruction"
              value={systemInstruction}
              onChange={(e) => setSystemInstruction(e.target.value)}
              className="w-full bg-gray-700 text-white p-2 rounded"
              rows="4"
            />
          </div>
          <div className="flex justify-end">
            <button type="button" onClick={onClose} className="mr-2 px-4 py-2 bg-gray-600 text-white rounded">Cancel</button>
            <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded">Save Changes</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AgentEditModal;