import React from 'react';

const BackgroundDesign = ({ design }) => {
  switch (design) {
    case 'inspiring':
      // Kept as is
      return (
        <div className="fixed inset-0 z-0 bg-gradient-to-br from-blue-900 via-purple-800 to-indigo-900 overflow-hidden">
          <div className="absolute inset-0 opacity-70">
            {[...Array(200)].map((_, i) => (
              <div
                key={i}
                className="absolute text-white"
                style={{
                  left: `${Math.random() * 100}%`,
                  top: `${Math.random() * 100}%`,
                  fontSize: `${Math.random() * 3 + 1}px`,
                  opacity: Math.random() * 0.8 + 0.2,
                }}
              >
                ✨
              </div>
            ))}
          </div>
          <div className="absolute inset-0 bg-gradient-radial from-transparent to-blue-900 opacity-60"></div>
          <div className="absolute bottom-0 right-0 text-9xl opacity-10 transform translate-y-1/4 translate-x-1/4">
            🌠
          </div>
        </div>
      );
    case 'serene':
      // Kept as is
      return (
        <div className="fixed inset-0 z-0 bg-gradient-to-br from-pink-200 via-purple-200 to-indigo-200 overflow-hidden">
          <div className="absolute inset-0 opacity-50">
            {[...Array(50)].map((_, i) => (
              <div
                key={i}
                className="absolute"
                style={{
                  left: `${Math.random() * 100}%`,
                  top: `${Math.random() * 100}%`,
                  fontSize: `${Math.random() * 40 + 20}px`,
                  opacity: Math.random() * 0.7 + 0.3,
                  transform: `rotate(${Math.random() * 360}deg)`,
                }}
              >
                {['🌸', '🌺', '🌷', '🌹', '🌼', '🌻'][Math.floor(Math.random() * 6)]}
              </div>
            ))}
          </div>
          <div className="absolute inset-0 bg-gradient-radial from-white to-transparent opacity-70"></div>
          <div className="absolute bottom-0 left-0 text-9xl opacity-20 transform -translate-y-1/4 -translate-x-1/4">
            🦋
          </div>
        </div>
      );
    case 'motivating':
      // Completely redesigned with emojis
      return (
        <div className="fixed inset-0 z-0 overflow-hidden bg-gradient-to-br from-yellow-400 via-orange-500 to-red-500">
          <div className="absolute inset-0 opacity-70">
            {[...Array(40)].map((_, i) => {
              const emojis = ['💪', '🏆', '🚀', '⭐', '🔥', '💡', '🎯'];
              return (
                <div
                  key={i}
                  className="absolute text-4xl"
                  style={{
                    left: `${Math.random() * 100}%`,
                    top: `${Math.random() * 100}%`,
                    opacity: Math.random() * 0.5 + 0.1,
                    transform: `rotate(${Math.random() * 360}deg)`,
                  }}
                >
                  {emojis[Math.floor(Math.random() * emojis.length)]}
                </div>
              );
            })}
          </div>
          <div className="absolute inset-0 bg-gradient-radial from-transparent to-orange-600 opacity-40"></div>
          <div className="absolute top-1/4 left-1/4 w-1/2 h-1/2 bg-white opacity-10 rounded-full blur-3xl"></div>
          <div className="absolute bottom-0 right-0 text-9xl opacity-20 transform translate-y-1/4 translate-x-1/4">
            🏆
          </div>
        </div>
      );
    case 'elegant':
      // Kept as is
      return (
        <div className="fixed inset-0 z-0 overflow-hidden bg-gradient-to-br from-gray-900 to-black">
          <div className="absolute inset-0 bg-gradient-radial from-transparent to-black opacity-70"></div>
        </div>
      );
    case 'futuristic':
      // Kept as is
      return (
        <div className="fixed inset-0 z-0 overflow-hidden bg-black">
          <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIj4KPHJlY3Qgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiIGZpbGw9Im5vbmUiPjwvcmVjdD4KPGNpcmNsZSBjeD0iNTAiIGN5PSI1MCIgcj0iNDAiIHN0cm9rZT0icmdiYSgwLCAyNTUsIDI1NSwgMC4xKSIgc3Ryb2tlLXdpZHRoPSIwLjUiIGZpbGw9Im5vbmUiPjwvY2lyY2xlPgo8L3N2Zz4=')] opacity-30"></div>
          <div className="absolute inset-0">
            {[...Array(50)].map((_, i) => (
              <div
                key={i}
                className="absolute bg-blue-500 rounded-full"
                style={{
                  left: `${Math.random() * 100}%`,
                  top: `${Math.random() * 100}%`,
                  width: `${Math.random() * 4 + 1}px`,
                  height: `${Math.random() * 4 + 1}px`,
                  opacity: Math.random() * 0.5 + 0.3,
                  boxShadow: '0 0 10px 2px rgba(59, 130, 246, 0.5)',
                }}
              ></div>
            ))}
          </div>
          <div className="absolute inset-0 bg-gradient-radial from-transparent to-black opacity-60"></div>
        </div>
      );
    case 'luxury':
      // Kept as is
      return (
        <div className="fixed inset-0 z-0 overflow-hidden bg-gradient-to-br from-gray-900 to-black">
          <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIj4KPHJlY3Qgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiIGZpbGw9Im5vbmUiPjwvcmVjdD4KPHBhdGggZD0iTTAgMCBMNTAgNTAgTDEwMCAwIE0wIDEwMCBMNTAgNTAgTDEwMCAxMDAiIHN0cm9rZT0icmdiYSgxODQsIDEzNCwgMTEsIDAuMSkiIHN0cm9rZS13aWR0aD0iMC41IiBmaWxsPSJub25lIj48L3BhdGg+Cjwvc3ZnPg==')] opacity-30"></div>
          <div className="absolute inset-0 bg-gradient-radial from-transparent to-black opacity-60"></div>
          <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-transparent via-yellow-700 to-transparent opacity-50"></div>
          <div className="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-transparent via-yellow-700 to-transparent opacity-50"></div>
        </div>
      );
    case 'dark':
      // Kept as is
      return (
        <div className="fixed inset-0 z-0 overflow-hidden bg-gray-900">
          <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2MCIgaGVpZ2h0PSI2MCI+CjxyZWN0IHdpZHRoPSI2MCIgaGVpZ2h0PSI2MCIgZmlsbD0ibm9uZSI+PC9yZWN0Pgo8cGF0aCBkPSJNMzAgMzAgTDYwIDMwIE0zMCAzMCBMMzAgNjAiIHN0cm9rZT0icmdiYSgyNTUsIDI1NSwgMjU1LCAwLjAzKSIgc3Ryb2tlLXdpZHRoPSIwLjUiPjwvcGF0aD4KPC9zdmc+')] opacity-20"></div>
          <div className="absolute inset-0 bg-gradient-radial from-transparent to-gray-900 opacity-80"></div>
        </div>
        
      );
    default:
      return null;
  }
};

export default BackgroundDesign;