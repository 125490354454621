import React, { useState, useContext } from 'react';
import { XIcon } from '@heroicons/react/solid';
import { addDoc, collection } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from './firebase';
import { AuthContext } from './AuthContext';
import { generateResponse } from './services/openai';

const AgentCreationModal = ({ isOpen, onClose, onSave }) => {
  const { user } = useContext(AuthContext);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [categories, setCategories] = useState('');
  const [profilePicture, setProfilePicture] = useState(null);
  const [systemInstruction, setSystemInstruction] = useState('');
  const [isPinned, setIsPinned] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isGeneratingInstruction, setIsGeneratingInstruction] = useState(false);

  const handleProfilePictureChange = (e) => {
    if (e.target.files[0]) {
      setProfilePicture(e.target.files[0]);
    }
  };

  const handleGenerateInstruction = async () => {
    if (!description) {
      alert("Please provide a description first.");
      return;
    }

    setIsGeneratingInstruction(true);
    try {
      const prompt = `Based on the following description of an AI agent, generate a comprehensive system instruction that will guide the AI to behave and respond according to this description. The instruction should be detailed and specific, covering the agent's role, knowledge areas, communication style, and any limitations or special considerations.

Description: ${description}

Generate a system instruction for this AI agent:`;

      const response = await generateResponse([{ role: 'user', content: prompt }], '', {}, null, () => {});
      const generatedInstruction = response.aiResponse; // Correct extraction

      if (generatedInstruction) {
        setSystemInstruction(generatedInstruction);
      } else {
        throw new Error("No result from OpenAI");
      }
    } catch (error) {
      console.error('Error generating system instruction:', error);
      alert('Failed to generate system instruction. Please try again.');
    } finally {
      setIsGeneratingInstruction(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user) {
      console.error('User not authenticated');
      return;
    }
    setIsLoading(true);

    try {
      let profilePictureUrl = '';
      if (profilePicture) {
        const storageRef = ref(storage, `users/${user.uid}/agentProfilePictures/${Date.now()}_${profilePicture.name}`);
        await uploadBytes(storageRef, profilePicture);
        profilePictureUrl = await getDownloadURL(storageRef);
      }

      const newAgent = {
        title,
        description,
        categories: categories.split(',').map(cat => cat.trim()),
        profilePicture: profilePictureUrl,
        systemInstruction,
        isPinned,
        createdAt: new Date().toISOString(),
      };

      const docRef = await addDoc(collection(db, `users/${user.uid}/agents`), newAgent);
      onSave({ id: docRef.id, ...newAgent });
      onClose();
    } catch (error) {
      console.error('Error creating agent:', error);
      alert('Failed to create agent. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-gray-800 p-6 rounded-lg w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold text-white">Create AI Agent</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-white">
            <XIcon className="h-6 w-6" />
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-300 mb-2" htmlFor="title">Title:</label>
            <input
              id="title"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="E.g., Life Coach"
              className="w-full bg-gray-700 text-white p-2 rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-300 mb-2" htmlFor="description">Description:</label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Explain what the AI agent does and how to use it."
              className="w-full bg-gray-700 text-white p-2 rounded"
              rows="3"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-300 mb-2" htmlFor="categories">Categories (Optional):</label>
            <input
              id="categories"
              type="text"
              value={categories}
              onChange={(e) => setCategories(e.target.value)}
              placeholder="Enter categories separated by commas"
              className="w-full bg-gray-700 text-white p-2 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-300 mb-2" htmlFor="profilePicture">Profile Picture:</label>
            <input
              id="profilePicture"
              type="file"
              onChange={handleProfilePictureChange}
              accept="image/*"
              className="w-full bg-gray-700 text-white p-2 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-300 mb-2" htmlFor="systemInstruction">System Instruction:</label>
            <div className="flex items-center mb-2">
              <button
                type="button"
                onClick={handleGenerateInstruction}
                className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors duration-200 mr-2"
                disabled={isGeneratingInstruction || !description}
              >
                {isGeneratingInstruction ? 'Generating...' : 'Generate Instruction'}
              </button>
              <span className="text-gray-400 text-sm">
                {isGeneratingInstruction ? 'This may take a few seconds...' : 'Based on the description'}
              </span>
            </div>
            <textarea
              id="systemInstruction"
              value={systemInstruction}
              onChange={(e) => setSystemInstruction(e.target.value)}
              placeholder="Enter the system instruction for the AI agent"
              className="w-full bg-gray-700 text-white p-2 rounded"
              rows="6"
              required
            />
          </div>
          <div className="mb-4">
            <label className="flex items-center text-gray-300">
              <input
                type="checkbox"
                checked={isPinned}
                onChange={(e) => setIsPinned(e.target.checked)}
                className="mr-2"
              />
              Pin this AI agent on the main page
            </label>
          </div>
          <button 
            type="submit" 
            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 disabled:bg-blue-400"
            disabled={isLoading || isGeneratingInstruction}
          >
            {isLoading ? 'Creating...' : 'Create Agent'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AgentCreationModal;
