import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { SparklesIcon, PlusIcon, MenuIcon } from '@heroicons/react/solid';
import { createNewConversation, setCurrentAgent } from './redux/chatSlice';
import { AuthContext } from './AuthContext';

function Header({ cycleDesign, currentDesign, toggleSidebar }) {
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);

  const handleNewChat = () => {
    if (user) {
      dispatch(createNewConversation(user.uid));
      dispatch(setCurrentAgent(null));
    } else {
      console.error('User not authenticated');
    }
  };

  return (
    <header className="bg-gradient-to-r from-gray-900 to-gray-800 text-white shadow-lg relative z-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-3 flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <button
            onClick={toggleSidebar}
            className="p-2 rounded-full hover:bg-white hover:bg-opacity-20 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
            title="Toggle sidebar"
          >
            <MenuIcon className="h-6 w-6 text-gold" />
          </button>
          <h1 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-gold to-yellow-500">
            WorifyAI
          </h1>
        </div>

        <div className="flex items-center space-x-3">
          <button
            onClick={handleNewChat}
            className="p-2 rounded-full bg-gold text-gray-900 hover:bg-yellow-500 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-gold"
            title="Start a new chat"
          >
            <PlusIcon className="h-6 w-6" />
          </button>
          <button
            onClick={cycleDesign}
            className="p-2 rounded-full bg-gray-700 hover:bg-gray-600 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
            title={`Current design: ${currentDesign}`}
          >
            <SparklesIcon className="h-6 w-6 text-gold" />
          </button>
        </div>
      </div>
    </header>
  );
}

export default Header;