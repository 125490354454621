import React, { useState, useContext, useEffect } from 'react';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { store } from './redux/store';
import ChatArea from './ChatArea';
import Header from './Header';
import Sidebar from './Sidebar';
import BackgroundDesign from './BackgroundDesign';
import { AuthProvider, AuthContext } from './AuthContext';
import LoginPage from './LoginPage';
import DailyNotebook from './DailyNotebook';
import AdminPanel from './AdminPanel';
import { checkAdminStatus } from './redux/userSlice';
import './markdown.css';

const designs = ['luxury', 'inspiring', 'motivating', 'elegant', 'futuristic', 'serene'];

function AppContent() {
  const [designIndex, setDesignIndex] = useState(0);
  const [isDailyNotebookOpen, setIsDailyNotebookOpen] = useState(false);
  const [isAdminPanelOpen, setIsAdminPanelOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { user, loading } = useContext(AuthContext);
  const dispatch = useDispatch();
  const isAdmin = useSelector(state => state.user.isAdmin);

  useEffect(() => {
    if (user) {
      dispatch(checkAdminStatus(user.uid));
    }
  }, [user, dispatch]);

  const cycleDesign = () => {
    setDesignIndex((prevIndex) => (prevIndex + 1) % designs.length);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <LoginPage />;
  }

  return (
    <div className="h-screen flex flex-col relative overflow-hidden">
      <BackgroundDesign design={designs[designIndex]} />
      <Header 
        cycleDesign={cycleDesign} 
        currentDesign={designs[designIndex]} 
        toggleSidebar={toggleSidebar}
      />
      <div className="flex-1 flex overflow-hidden relative z-10">
        <div className="flex-1 flex flex-col relative">
          <ChatArea />
          {isAdminPanelOpen && isAdmin && (
            <div className="absolute inset-0 z-50">
              <AdminPanel onClose={() => setIsAdminPanelOpen(false)} />
            </div>
          )}
          {isDailyNotebookOpen && (
            <div className="absolute inset-0 z-50">
              <DailyNotebook
                cycleDesign={cycleDesign}
                currentDesign={designs[designIndex]}
                onClose={() => setIsDailyNotebookOpen(false)}
              />
            </div>
          )}
        </div>
        {isSidebarOpen && (
          <div className="fixed inset-0 z-40" onClick={toggleSidebar}>
            <div 
              className="absolute left-0 top-0 bottom-0 w-64 bg-gray-900 shadow-lg z-50" 
              onClick={e => e.stopPropagation()}
            >
              <Sidebar onClose={toggleSidebar} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function App() {
  return (
    <Provider store={store}>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Provider>
  );
}

export default App;