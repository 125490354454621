import React, { useState } from 'react';
import { auth } from './firebase';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const firestore = getFirestore();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      if (isSignUp) {
        console.log('Attempting to create user with email:', email);
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const userId = userCredential.user.uid;
        console.log('User created successfully. User ID:', userId);
        
        console.log('Attempting to save email to Firestore');
        try {
          await setDoc(doc(firestore, 'users', userId), {
            email: email,
            createdAt: new Date().toISOString()
          });
          console.log('Email saved to Firestore successfully');
          setSuccess('Account created successfully!');
        } catch (firestoreError) {
          console.error('Firestore error:', firestoreError.code, firestoreError.message);
          setError(`Failed to save user data: ${firestoreError.message}`);
        }
      } else {
        await signInWithEmailAndPassword(auth, email, password);
        setSuccess('Logged in successfully!');
      }
    } catch (error) {
      console.error('Authentication error:', error.code, error.message);
      setError(`Authentication error: ${error.message}`);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900">
      <div className="bg-gray-800 p-8 rounded-lg shadow-md w-96">
        <h2 className="text-2xl font-bold mb-4 text-white">{isSignUp ? 'Sign Up' : 'Log In'}</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {success && <p className="text-green-500 mb-4">{success}</p>}
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-2 mb-4 bg-gray-700 text-white rounded"
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-2 mb-4 bg-gray-700 text-white rounded"
            required
          />
          <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition duration-200">
            {isSignUp ? 'Sign Up' : 'Log In'}
          </button>
        </form>
        <button
          onClick={() => {
            setIsSignUp(!isSignUp);
            setError('');
            setSuccess('');
          }}
          className="w-full mt-4 text-blue-300 underline hover:text-blue-400 transition duration-200"
        >
          {isSignUp ? 'Already have an account? Log in' : 'Need an account? Sign up'}
        </button>
      </div>
    </div>
  );
}

export default LoginPage;