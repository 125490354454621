import React, { useState, useCallback, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { ClipboardCopyIcon, PencilIcon, DownloadIcon, EyeIcon } from '@heroicons/react/solid';
import { saveAs } from 'file-saver';
import ArtifactPreview from './ArtifactPreview';

const userMessageStyle = "text-base leading-relaxed font-normal";
const aiMessageStyle = "text-[0.94em] leading-relaxed font-normal";
const DEFAULT_AI_PROFILE_IMAGE = "https://cch-files.edge.live.ds25.io/cch/v/ad17b917-8265-4d1b-aa16-faa982a22c74/files/667e740f07ec2_wf.jpg";

const parseMarkdown = (text) => {
  const lines = text.split('\n');
  let html = '';
  let inList = false;

  lines.forEach((line, index) => {
    // Handle numbered lists
    const listMatch = line.match(/^(\d+)\.\s(.+)/);
    if (listMatch) {
      if (!inList) {
        html += '<ol>';
        inList = true;
      }
      html += `<li value="${listMatch[1]}">${parseInline(listMatch[2])}</li>`;
    } else if (inList) {
      html += '</ol>';
      inList = false;
    }

    // Handle headings
    const headingMatch = line.match(/^(#{1,6})\s(.+)/);
    if (headingMatch) {
      const level = headingMatch[1].length;
      html += `<h${level}>${parseInline(headingMatch[2])}</h${level}>`;
    } 
    // Handle regular text
    else if (!listMatch) {
      html += parseInline(line) + (index < lines.length - 1 ? '<br>' : '');
    }
  });

  if (inList) {
    html += '</ol>';
  }

  return html;
};

const parseInline = (text) => {
  // Handle bold text
  text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  // Handle italic text
  text = text.replace(/\*(.*?)\*/g, '<em>$1</em>');
  // Handle inline code
  text = text.replace(/`(.*?)`/g, '<code>$1</code>');
  return text;
};

const copyRichText = async (text) => {
  const html = parseMarkdown(text);
  
  // Wrap the HTML content in a pre-formatted div
  const wrappedHtml = `
    <div style="white-space: pre-wrap; font-family: Arial, sans-serif; font-size: 14px;">
      ${html}
    </div>
  `;

  try {
    await navigator.clipboard.write([
      new ClipboardItem({
        'text/html': new Blob([wrappedHtml], { type: 'text/html' }),
        'text/plain': new Blob([text], { type: 'text/plain' })
      })
    ]);
    console.log('Rich text copied to clipboard');
  } catch (err) {
    console.error('Failed to copy rich text: ', err);
    // Fallback to plain text
    await navigator.clipboard.writeText(text);
    console.log('Plain text copied to clipboard');
  }
};

function ChatMessage({ user, ai, message, images, profilePicture, aiProfilePicture, onImageClick, onMessageClick, isEditing, isPending }) {
  const [showCopyIcon, setShowCopyIcon] = useState(false);
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [previewArtifact, setPreviewArtifact] = useState(null);
  const [currentAiImage, setCurrentAiImage] = useState(DEFAULT_AI_PROFILE_IMAGE);
  const imageRef = useRef(null);

  useEffect(() => {
    if (ai && aiProfilePicture) {
      const img = new Image();
      img.onload = () => {
        setCurrentAiImage(aiProfilePicture);
      };
      img.onerror = () => {
        setCurrentAiImage(DEFAULT_AI_PROFILE_IMAGE);
      };
      img.src = aiProfilePicture;
    } else if (ai) {
      setCurrentAiImage(DEFAULT_AI_PROFILE_IMAGE);
    }
  }, [ai, aiProfilePicture]);

  const defaultImage = useCallback((letter) => (
    <div className="w-8 h-8 rounded-full bg-indigo-600 flex items-center justify-center text-white font-bold">
      {letter}
    </div>
  ), []);

  const handleCopy = async (text) => {
    try {
      await copyRichText(text);
      // You can add a notification here
    } catch (err) {
      console.error('Could not copy text: ', err);
    }
  };

  const handleDownload = (code, language) => {
    const blob = new Blob([code], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, `code.${language}`);
  };

  const handleEdit = () => {
    onMessageClick();
  };

  const handlePreviewArtifact = (code, language) => {
    setPreviewArtifact({ code, language });
  };

  const closePreview = () => {
    setPreviewArtifact(null);
  };

  const MessageContent = ({ children, isAI }) => (
    <div className={`${isAI ? aiMessageStyle : userMessageStyle} text-white break-words`}>
      {children}
    </div>
  );

  return (
    <>
      <div 
        className={`chat-message flex ${ai ? 'justify-start' : 'justify-end'} mb-4 ${isPending ? 'opacity-50' : ''}`}
        onMouseEnter={() => ai ? setShowCopyIcon(true) : setShowEditIcon(true)}
        onMouseLeave={() => ai ? setShowCopyIcon(false) : setShowEditIcon(false)}
      >
        <div className={`flex ${ai ? 'flex-row' : 'flex-row-reverse'} max-w-full sm:max-w-3xl`}>
          <div className="flex-shrink-0 cursor-pointer" onClick={() => onImageClick(ai)}>
            {ai ? 
              <img 
                ref={imageRef}
                src={currentAiImage} 
                alt="AI" 
                className="w-8 h-8 rounded-full object-cover" 
                onError={() => setCurrentAiImage(DEFAULT_AI_PROFILE_IMAGE)}
              /> :
              (profilePicture ? 
                <img src={profilePicture} alt="User" className="w-8 h-8 rounded-full object-cover" /> : 
                defaultImage('U')
              )
            }
          </div>
          <div 
            className={`mx-2 ${ai ? 'bg-gray-700' : isEditing ? 'bg-yellow-600' : 'bg-blue-600'} rounded-lg px-4 py-2 relative overflow-hidden`}
          >
            {images && images.length > 0 && (
              <div className="flex flex-wrap mb-2">
                {images.map((imageUrl, index) => (
                  <img 
                    key={index}
                    src={imageUrl} 
                    alt={`Message ${index + 1}`} 
                    className="max-w-full max-h-[300px] w-auto h-auto object-contain rounded mr-2 mb-2" 
                  />
                ))}
              </div>
            )}
            <MessageContent isAI={ai}>
              <ReactMarkdown
                className="markdown-content"
                components={{
                  p: ({node, ...props}) => <p className="mb-2 last:mb-0" {...props} />,
                  a: ({node, ...props}) => <a className="text-blue-300 hover:underline" {...props} />,
                  ul: ({node, ...props}) => <ul className="list-disc pl-4 mb-2" {...props} />,
                  ol: ({node, ...props}) => <ol className="list-decimal pl-4 mb-2" {...props} />,
                  li: ({node, ordered, index, ...props}) => {
                    if (ordered) {
                      const value = node.children[0].value;
                      const match = value.match(/^(\d+)\.\s(.+)/);
                      if (match) {
                        return <li value={match[1]} className="mb-1">{match[2]}</li>;
                      }
                    }
                    return <li className="mb-1" {...props} />;
                  },
                  h1: ({node, ...props}) => <h1 className="text-[1.8em] font-bold mb-2 mt-4" {...props} />,
                  h2: ({node, ...props}) => <h2 className="text-[1.35em] font-bold mb-2 mt-3" {...props} />,
                  h3: ({node, ...props}) => <h3 className="text-[1.125em] font-bold mb-2 mt-3" {...props} />,
                  code({node, inline, className, children, ...props}) {
                    const match = /language-(\w+)/.exec(className || '')
                    return !inline && match ? (
                      <div className="relative group bg-[#2d2d2d] rounded-md mt-2 text-xs">
                        <div className="flex justify-between items-center bg-[#1e1e1e] px-2 py-1 text-[0.6rem] text-[#e0e0e0]">
                          <span>{match[1]}</span>
                          <div className="flex space-x-2">
                            <button 
                              onClick={() => handleCopy(String(children)).catch(console.error)}
                              className="bg-[#4a4a4a] text-white border-none px-1 py-0.5 rounded text-[0.6rem] cursor-pointer hover:bg-[#5a5a5a]"
                              aria-label="Copy code"
                            >
                              <ClipboardCopyIcon className="h-3 w-3" />
                            </button>
                            <button 
                              onClick={() => handleDownload(String(children), match[1])}
                              className="bg-[#4a4a4a] text-white border-none px-1 py-0.5 rounded text-[0.6rem] cursor-pointer hover:bg-[#5a5a5a]"
                              aria-label="Download code"
                            >
                              <DownloadIcon className="h-3 w-3" />
                            </button>
                            <button 
                              onClick={() => handlePreviewArtifact(String(children), match[1])}
                              className="bg-[#4a4a4a] text-white border-none px-1 py-0.5 rounded text-[0.6rem] cursor-pointer hover:bg-[#5a5a5a]"
                              aria-label="Preview artifact"
                            >
                              <EyeIcon className="h-3 w-3" />
                            </button>
                          </div>
                        </div>
                        <SyntaxHighlighter
                          style={atomDark}
                          language={match[1]}
                          PreTag="div"
                          {...props}
                          customStyle={{
                            fontSize: '0.7rem',
                            padding: '0.5rem',
                            maxHeight: '150px',
                            overflowY: 'auto',
                            overflowX: 'auto',
                            wordWrap: 'break-word',
                            whiteSpace: 'pre-wrap'
                          }}
                        >
                          {String(children).replace(/\n$/, '')}
                        </SyntaxHighlighter>
                      </div>
                    ) : (
                      <code className="bg-gray-800 rounded px-1 py-0.5 break-words" {...props}>
                        {children}
                      </code>
                    )
                  }
                }}
              >
                {message}
              </ReactMarkdown>
            </MessageContent>
            {ai && showCopyIcon && !isPending && (
              <button 
                onClick={() => handleCopy(message).catch(console.error)}
                className="absolute top-2 right-2 text-gray-400 hover:text-white transition-colors duration-200"
                aria-label="Copy message"
              >
                <ClipboardCopyIcon className="h-5 w-5" />
              </button>
            )}
            {!ai && showEditIcon && !isEditing && !isPending && (
              <button 
                onClick={handleEdit}
                className="absolute top-2 right-2 text-gray-400 hover:text-white transition-colors duration-200"
                aria-label="Edit message"
              >
                <PencilIcon className="h-5 w-5" />
              </button>
            )}
          </div>
        </div>
      </div>
      {previewArtifact && (
        <ArtifactPreview
          code={previewArtifact.code}
          language={previewArtifact.language}
          onClose={closePreview}
          onCopy={() => handleCopy(previewArtifact.code).catch(console.error)}
          onDownload={() => handleDownload(previewArtifact.code, previewArtifact.language)}
        />
      )}
    </>
  );
}

export default ChatMessage;