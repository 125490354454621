// src/components/ArtifactPreview.js
import React, { useState, useEffect } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { XIcon, ClipboardCopyIcon, DownloadIcon, CodeIcon } from '@heroicons/react/solid';

function ArtifactPreview({ code, language, onClose, onCopy, onDownload }) {
  const [preview, setPreview] = useState('');
  const [showCode, setShowCode] = useState(false);

  useEffect(() => {
    generatePreview();
  }, [code, language]);

  const generatePreview = () => {
    switch (language.toLowerCase()) {
      case 'html':
      case 'css':
        setPreview(`
          <html>
            <head>
              <style>${language.toLowerCase() === 'css' ? code : ''}</style>
            </head>
            <body>${language.toLowerCase() === 'html' ? code : ''}</body>
          </html>
        `);
        break;
      case 'javascript':
        try {
          // For simple JavaScript, we can try to evaluate it
          // This is not secure for production use!
          const result = eval(code);
          setPreview(JSON.stringify(result, null, 2));
        } catch (error) {
          setPreview(`Error executing JavaScript: ${error.message}`);
        }
        break;
      default:
        // For other languages, we'll just show the code
        setPreview(null);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 rounded-lg w-full max-w-3xl max-h-[90vh] flex flex-col">
        <div className="flex justify-between items-center p-4 border-b border-gray-700">
          <h2 className="text-xl font-bold text-white">Code Preview</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-white">
            <XIcon className="h-6 w-6" />
          </button>
        </div>
        <div className="flex-1 overflow-auto p-4">
          {preview ? (
            showCode ? (
              <SyntaxHighlighter
                language={language}
                style={atomDark}
                customStyle={{
                  margin: 0,
                  borderRadius: '0.375rem',
                }}
              >
                {code}
              </SyntaxHighlighter>
            ) : (
              <iframe
                srcDoc={preview}
                title="Code Preview"
                className="w-full h-[85vh] border-0"
                sandbox="allow-scripts"
              />
            )
          ) : (
            <SyntaxHighlighter
              language={language}
              style={atomDark}
              customStyle={{
                margin: 0,
                borderRadius: '0.375rem',
              }}
            >
              {code}
            </SyntaxHighlighter>
          )}
        </div>
        <div className="flex justify-end space-x-4 p-4 border-t border-gray-700">
          {preview && (
            <button
              onClick={() => setShowCode(!showCode)}
              className="flex items-center space-x-2 px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
            >
              <CodeIcon className="h-5 w-5" />
              <span>{showCode ? 'Show Preview' : 'Show Code'}</span>
            </button>
          )}
          <button
            onClick={onCopy}
            className="flex items-center space-x-2 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
          >
            <ClipboardCopyIcon className="h-5 w-5" />
            <span>Copy</span>
          </button>
          <button
            onClick={onDownload}
            className="flex items-center space-x-2 px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
          >
            <DownloadIcon className="h-5 w-5" />
            <span>Download</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ArtifactPreview;
