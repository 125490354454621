import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PlusIcon, CogIcon, LogoutIcon, TrashIcon, ClipboardListIcon, SearchIcon, PencilIcon, CheckIcon, XIcon, BookOpenIcon } from '@heroicons/react/outline';
import { createNewConversation, setCurrentConversation, setCurrentAgent, fetchAgents, deleteConversation, searchConversations, clearSearchResults, fetchUserData, updateConversationTitle, deleteAgent, pinAgent, unpinAgent } from './redux/chatSlice';
import { checkAdminStatus } from './redux/userSlice';
import AgentCreationModal from './AgentCreationModal';
import AgentEditModal from './AgentEditModal';
import UserProfileSettings from './UserProfileSettings';
import TaskManagementPopup from './TaskManagementPopup';
import { signOut } from 'firebase/auth';
import { auth } from './firebase';
import { AuthContext } from './AuthContext';
import DailyNotebook from './DailyNotebook';
import AdminPanel from './AdminPanel';

function Sidebar({ cycleDesign, currentDesign }) {
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);
  const conversations = useSelector(state => state.chat.conversations);
  const searchResults = useSelector(state => state.chat.searchResults);
  const currentConversationId = useSelector(state => state.chat.currentConversation);
  const agents = useSelector(state => state.chat.agents);
  const currentAgent = useSelector(state => state.chat.currentAgent);
  const isAdmin = useSelector(state => state.user.isAdmin);

  const [isAgentModalOpen, setIsAgentModalOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [editingAgent, setEditingAgent] = useState(null);
  const [isTaskPopupOpen, setIsTaskPopupOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [editingTitle, setEditingTitle] = useState(null);
  const [newTitle, setNewTitle] = useState('');
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [isAllAgentsPopupOpen, setIsAllAgentsPopupOpen] = useState(false);
  const [agentSearchTerm, setAgentSearchTerm] = useState('');
  const [showDailyNotebook, setShowDailyNotebook] = useState(false);
  const [showAdminPanel, setShowAdminPanel] = useState(false);

  useEffect(() => {
    if (user) {
      dispatch(fetchAgents(user.uid));
      dispatch(fetchUserData(user.uid));
      dispatch(checkAdminStatus(user.uid));
    }
  }, [user, dispatch]);

  const handleNewChat = () => {
    if (user) {
      dispatch(createNewConversation(user.uid));
      dispatch(setCurrentAgent(null));
    } else {
      console.error('User not authenticated');
    }
  };

  const handleAgentCreation = (newAgent) => {
    dispatch(fetchAgents(user.uid));
    setIsAgentModalOpen(false);
  };

  const handleAgentSelection = (agentId) => {
    dispatch(setCurrentAgent(agentId));
  };

  const handleAgentEdit = (agent) => {
    setEditingAgent(agent);
    setIsAllAgentsPopupOpen(false);
  };

  const handleAgentDelete = (agentId) => {
    setDeleteConfirmation(agentId);
  };

  const confirmAgentDelete = () => {
    if (user && deleteConfirmation) {
      dispatch(deleteAgent({ userId: user.uid, agentId: deleteConfirmation }));
      if (currentAgent === deleteConfirmation) {
        dispatch(setCurrentAgent(null));
      }
      setDeleteConfirmation(null);
    }
  };

  const cancelAgentDelete = () => {
    setDeleteConfirmation(null);
  };

  const handleSettingsClick = () => {
    setIsSettingsOpen(true);
  };

  const handleCloseSettings = () => {
    setIsSettingsOpen(false);
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleDeleteConversation = (conversationId) => {
    if (user) {
      dispatch(deleteConversation({ userId: user.uid, conversationId }));
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (user && searchTerm.trim()) {
      dispatch(searchConversations({ userId: user.uid, searchTerm: searchTerm.trim() }));
    } else {
      dispatch(clearSearchResults());
    }
  };

  const handleEditTitle = (id, currentTitle) => {
    setEditingTitle(id);
    setNewTitle(currentTitle);
  };

  const handleSaveTitle = (id) => {
    if (newTitle.trim() && newTitle !== conversations[id].title) {
      dispatch(updateConversationTitle({ userId: user.uid, conversationId: id, title: newTitle.trim() }));
    }
    setEditingTitle(null);
  };

  const handleCancelEdit = () => {
    setEditingTitle(null);
    setNewTitle('');
  };

  const handlePinAgent = (agentId) => {
    if (user) {
      dispatch(pinAgent({ userId: user.uid, agentId }));
    }
  };

  const handleUnpinAgent = (agentId) => {
    if (user) {
      dispatch(unpinAgent({ userId: user.uid, agentId }));
    }
  };

  const displayedConversations = Object.keys(searchResults).length > 0 ? searchResults : conversations;
  const pinnedAgents = agents.filter(agent => agent.isPinned);
  const unpinnedAgents = agents.filter(agent => !agent.isPinned);
  const displayedAgents = pinnedAgents.length > 0 ? pinnedAgents : unpinnedAgents.slice(0, 2);
  const filteredAgents = agents.filter(agent => 
    agent.title.toLowerCase().includes(agentSearchTerm.toLowerCase())
  );

  if (isSettingsOpen) {
    return (
      <div className="w-64 bg-gray-900 flex flex-col h-full text-white">
        <UserProfileSettings onClose={handleCloseSettings} />
      </div>
    );
  }

  if (showAdminPanel && isAdmin) {
    return (
      <div className="w-64 bg-gray-900 flex flex-col h-full text-white">
        <AdminPanel onClose={() => setShowAdminPanel(false)} />
      </div>
    );
  }

  return (
    <div className="w-64 bg-gray-900 flex flex-col h-full text-white">
      <div className="p-4 space-y-4">
        <button 
          onClick={handleNewChat}
          className="w-full bg-gold text-black rounded-md py-2 px-4 flex items-center justify-center hover:bg-gold-dark transition-colors duration-200"
        >
          <PlusIcon className="h-5 w-5 mr-2" />
          New Chat
        </button>
        <form onSubmit={handleSearch} className="relative">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search conversations..."
            className="w-full bg-gray-800 text-white rounded-md py-2 pl-4 pr-10 focus:outline-none focus:ring-2 focus:ring-gold"
          />
          <button
            type="submit"
            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-white"
          >
            <SearchIcon className="h-5 w-5" />
          </button>
        </form>
      </div>

      {showDailyNotebook && (
        <DailyNotebook
          cycleDesign={cycleDesign}
          currentDesign={currentDesign}
          onClose={() => setShowDailyNotebook(false)}
        />
      )}

      <nav className="flex-1 overflow-y-auto">
        <div className="px-4 py-2 text-gray-400 uppercase text-xs">
          {pinnedAgents.length > 0 ? 'Pinned AI Agents' : 'AI Agents'}
        </div>
        {displayedAgents.map(agent => (
          <div 
            key={agent.id}
            className={`flex items-center px-4 py-2 text-sm text-gray-300 hover:bg-gray-800 cursor-pointer transition-colors duration-200 ${
              agent.id === currentAgent ? 'bg-gray-800 border-l-4 border-gold' : ''
            }`}
          >
            <div 
              className="flex-grow truncate"
              onClick={() => handleAgentSelection(agent.id)}
            >
              {agent.title}
            </div>
            <button
              onClick={() => agent.isPinned ? handleUnpinAgent(agent.id) : handlePinAgent(agent.id)}
              className="text-gray-500 hover:text-white ml-2"
            >
              {agent.isPinned ? <XIcon className="h-4 w-4" /> : <PlusIcon className="h-4 w-4" />}
            </button>
            <button
              onClick={() => handleAgentEdit(agent)}
              className="text-gray-500 hover:text-white ml-2"
            >
              <PencilIcon className="h-4 w-4" />
            </button>
            <button
              onClick={() => handleAgentDelete(agent.id)}
              className="text-gray-500 hover:text-red-500 ml-2"
            >
              <TrashIcon className="h-4 w-4" />
            </button>
          </div>
        ))}
        <button
          onClick={() => setIsAgentModalOpen(true)}
          className="w-full text-left px-4 py-2 text-sm text-blue-400 hover:bg-gray-800"
        >
          + Create New Agent
        </button>
        <button
          onClick={() => setIsAllAgentsPopupOpen(true)}
          className="w-full text-left px-4 py-2 text-sm text-blue-400 hover:bg-gray-800"
        >
          View All Agents
        </button>
        
        <div className="px-4 py-2 text-gray-400 uppercase text-xs mt-4">Conversations</div>
        {Object.entries(displayedConversations).map(([id, conversation]) => (
          <div 
            key={id}
            className={`flex items-center px-4 py-2 text-sm text-gray-300 hover:bg-gray-800 cursor-pointer transition-colors duration-200 ${
              id === currentConversationId ? 'bg-gray-800 border-l-4 border-gold' : ''
            }`}
          >
            {editingTitle === id ? (
              <div className="flex items-center w-full">
                <input
                  type="text"
                  value={newTitle}
                  onChange={(e) => setNewTitle(e.target.value)}
                  className="flex-grow bg-gray-700 text-white px-2 py-1 rounded mr-2"
                  autoFocus
                />
                <button onClick={() => handleSaveTitle(id)} className="text-green-500 hover:text-green-400 mr-2">
                  <CheckIcon className="h-4 w-4" />
                </button>
                <button onClick={handleCancelEdit} className="text-red-500 hover:text-red-400">
                  <XIcon className="h-4 w-4" />
                </button>
              </div>
            ) : (
              <>
                <div 
                  className="flex-grow truncate"
                  onClick={() => dispatch(setCurrentConversation(id))}
                >
                  {conversation.title || 'New Conversation'}
                </div>
                <button
                  onClick={() => handleEditTitle(id, conversation.title)}
                  className="text-gray-500 hover:text-white ml-2"
                >
                  <PencilIcon className="h-4 w-4" />
                </button>
                <button
                  onClick={() => handleDeleteConversation(id)}
                  className="text-gray-500 hover:text-red-500 ml-2"
                >
                  <TrashIcon className="h-4 w-4" />
                </button>
              </>
            )}
          </div>
        ))}
      </nav>
      <div className="mt-auto p-4 border-t border-gray-800">
        <button
          onClick={() => setShowDailyNotebook(true)}
          className="flex items-center px-4 py-2 text-gray-300 hover:bg-gray-800 transition-colors duration-200 w-full text-left"
        >
          <BookOpenIcon className="h-5 w-5 mr-3" />
          Notebook
        </button>
        <button 
          onClick={() => setIsTaskPopupOpen(true)}
          className="flex items-center px-4 py-2 text-gray-300 hover:bg-gray-800 transition-colors duration-200 w-full text-left"
        >
          <ClipboardListIcon className="h-5 w-5 mr-3" />
          Tasks
        </button>
        <button 
          onClick={handleSettingsClick} 
          className="flex items-center px-4 py-2 text-gray-300 hover:bg-gray-800 transition-colors duration-200 w-full text-left"
        >
          <CogIcon className="h-5 w-5 mr-3" />
          Settings
        </button>
        {isAdmin && (
          <button 
            onClick={() => setShowAdminPanel(true)}
            className="flex items-center px-4 py-2 text-gray-300 hover:bg-gray-800 transition-colors duration-200 w-full text-left"
          >
            <CogIcon className="h-5 w-5 mr-3" />
            Admin Panel
          </button>
        )}
        <button 
          onClick={handleSignOut}
          className="flex items-center px-4 py-2 text-gray-300 hover:bg-gray-800 transition-colors duration-200 w-full text-left"
        >
          <LogoutIcon className="h-5 w-5 mr-3" />
          Sign Out
        </button>
      </div>
      <AgentCreationModal
        isOpen={isAgentModalOpen}
        onClose={() => setIsAgentModalOpen(false)}
        onSave={handleAgentCreation}
      />
      {editingAgent && (
        <AgentEditModal
          agent={editingAgent}
          onClose={() => setEditingAgent(null)}
          userId={user.uid}
        />
      )}
<TaskManagementPopup
        isOpen={isTaskPopupOpen}
        onClose={() => setIsTaskPopupOpen(false)}
      />
      {isAllAgentsPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-800 p-6 rounded-lg max-w-md w-full max-h-[80vh] overflow-y-auto">
            <h3 className="text-xl font-bold mb-4">All AI Agents</h3>
            <input
              type="text"
              value={agentSearchTerm}
              onChange={(e) => setAgentSearchTerm(e.target.value)}
              placeholder="Search agents..."
              className="w-full bg-gray-700 text-white rounded-md py-2 px-4 mb-4 focus:outline-none focus:ring-2 focus:ring-gold"
            />
            {filteredAgents.map(agent => (
              <div 
                key={agent.id}
                className="flex items-center py-2 border-b border-gray-700 last:border-b-0"
              >
                <div 
                  className="flex-grow truncate cursor-pointer"
                  onClick={() => {
                    handleAgentSelection(agent.id);
                    setIsAllAgentsPopupOpen(false);
                  }}
                >
                  {agent.title}
                </div>
                <button
                  onClick={() => agent.isPinned ? handleUnpinAgent(agent.id) : handlePinAgent(agent.id)}
                  className="text-gray-500 hover:text-white ml-2"
                >
                  {agent.isPinned ? <XIcon className="h-4 w-4" /> : <PlusIcon className="h-4 w-4" />}
                </button>
                <button
                  onClick={() => handleAgentEdit(agent)}
                  className="text-gray-500 hover:text-white ml-2"
                >
                  <PencilIcon className="h-4 w-4" />
                </button>
                <button
                  onClick={() => handleAgentDelete(agent.id)}
                  className="text-gray-500 hover:text-red-500 ml-2"
                >
                  <TrashIcon className="h-4 w-4" />
                </button>
              </div>
            ))}
            <button
              onClick={() => {
                setIsAllAgentsPopupOpen(false);
                setIsAgentModalOpen(true);
              }}
              className="mt-4 w-full bg-blue-600 text-white rounded-md py-2 px-4 hover:bg-blue-700 transition-colors duration-200"
            >
              Create New Agent
            </button>
            <button
              onClick={() => setIsAllAgentsPopupOpen(false)}
              className="mt-2 w-full bg-gray-600 text-white rounded-md py-2 px-4 hover:bg-gray-700 transition-colors duration-200"
            >
              Close
            </button>
          </div>
        </div>
      )}
      {deleteConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-800 p-6 rounded-lg max-w-sm w-full">
            <h3 className="text-xl font-bold mb-4">Delete Agent</h3>
            <p className="mb-6">Are you sure you want to delete this agent? This action cannot be undone.</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={cancelAgentDelete}
                className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={confirmAgentDelete}
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Sidebar;