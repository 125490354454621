import React, { useState, useRef, useEffect } from 'react';
import { PaperAirplaneIcon, PhotographIcon, XCircleIcon } from '@heroicons/react/solid';

function InputArea({ onSendMessage, isLoading }) {
  const [input, setInput] = useState('');
  const [images, setImages] = useState([]);
  const textareaRef = useRef(null);

  useEffect(() => {
    adjustTextareaHeight();
  }, [input]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${Math.min(Math.max(textarea.scrollHeight, 50), 225)}px`;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendMessage();
  };

  const sendMessage = () => {
    if (input.trim() || images.length > 0) {
      onSendMessage(input, images);
      setInput('');
      setImages([]);
      if (textareaRef.current) {
        textareaRef.current.style.height = '50px';
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const newImages = files.slice(0, 5 - images.length);
    setImages(prevImages => [...prevImages, ...newImages]);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    const imageFiles = files.filter(file => file.type.startsWith('image/'));
    const newImages = imageFiles.slice(0, 5 - images.length);
    setImages(prevImages => [...prevImages, ...newImages]);
  };

  const removeImage = (index) => {
    setImages(prevImages => prevImages.filter((_, i) => i !== index));
  };

  return (
    <form onSubmit={handleSubmit} className="border-t border-gray-700 bg-black bg-opacity-50 p-4">
      <div className="flex items-end">
        <textarea
          ref={textareaRef}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyDown}
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
          placeholder="Enter a message..."
          className="flex-grow bg-gray-800 rounded-l py-3 px-6 focus:outline-none focus:ring-2 focus:ring-gold text-white placeholder-gray-400 resize-none min-h-[50px] max-h-[225px] overflow-y-auto"
          disabled={isLoading}
          rows={1}
        />
        <label className="cursor-pointer bg-gray-800 p-3 self-stretch flex items-center">
          <PhotographIcon className="h-6 w-6 text-gray-400" />
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="hidden"
            disabled={isLoading || images.length >= 5}
            multiple
          />
        </label>
        <button 
          type="submit" 
          className="bg-gold text-black rounded-r py-3 px-6 hover:bg-gold-dark focus:outline-none focus:ring-2 focus:ring-gold transition-colors duration-200 self-stretch flex items-center"
          disabled={isLoading}
        >
          <PaperAirplaneIcon className="h-5 w-5 transform rotate-90" />
        </button>
      </div>
      {images.length > 0 && (
        <div className="mt-2 flex flex-wrap">
          {images.map((image, index) => (
            <div key={index} className="relative mr-2 mb-2">
              <img 
                src={URL.createObjectURL(image)} 
                alt={`Uploaded ${index + 1}`} 
                className="h-20 object-cover rounded" 
              />
              <button 
                onClick={() => removeImage(index)}
                className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1"
                type="button"
              >
                <XCircleIcon className="h-4 w-4" />
              </button>
            </div>
          ))}
        </div>
      )}
    </form>
  );
}

export default InputArea;