import React, { useEffect, useRef, useState, useContext, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
  addMessageAndGenerateResponse,
  createNewConversation, 
  setCurrentAgent, 
  setCurrentConversation, 
  fetchUserData, 
  updateStreamingMessage, 
  clearStreamingMessage, 
  editMessage, 
  setEditingMessageIndex
} from './redux/chatSlice';
import { fetchMemories, addMemory } from './redux/memoriesSlice';
import { fetchStickyNotes, addStickyNote, updateStickyNote, deleteStickyNote } from './redux/stickyNotesSlice';
import ChatMessage from './ChatMessage';
import InputArea from './InputArea';
import { AuthContext } from './AuthContext';
import { uploadImage } from './firebase';
import BackgroundDesign from './BackgroundDesign';

const DEFAULT_AI_PROFILE_IMAGE = "https://cch-files.edge.live.ds25.io/cch/v/ad17b917-8265-4d1b-aa16-faa982a22c74/files/667e740f07ec2_wf.jpg";

function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);

    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  return isMobile;
}

function StickyNote({ note, onUpdate, onDelete }) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(note.title);
  const [editedContent, setEditedContent] = useState(note.content);

  const handleSave = () => {
    onUpdate(note.id, editedTitle, editedContent);
    setIsEditing(false);
  };

  return (
    <div className="bg-yellow-100 rounded-lg shadow-lg p-4 mb-4 transform hover:scale-105 transition-transform duration-200">
      {isEditing ? (
        <>
          <input
            className="w-full mb-2 p-1 border rounded bg-white"
            value={editedTitle}
            onChange={(e) => setEditedTitle(e.target.value)}
          />
          <textarea
            className="w-full mb-2 p-1 border rounded bg-white"
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
          />
          <div className="flex justify-end">
            <button onClick={handleSave} className="mr-2 text-sm text-blue-600">Save</button>
            <button onClick={() => setIsEditing(false)} className="text-sm text-red-600">Cancel</button>
          </div>
        </>
      ) : (
        <>
          <h3 className="text-lg font-bold mb-2 text-gray-800">{note.title}</h3>
          <p className="text-sm mb-2 text-gray-700">{note.content}</p>
          <div className="flex justify-end">
            <button onClick={() => setIsEditing(true)} className="mr-2 text-sm text-blue-600">Edit</button>
            <button onClick={() => onDelete(note.id)} className="text-sm text-red-600">Delete</button>
          </div>
        </>
      )}
    </div>
  );
}

function ChatArea() {
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);
  const currentConversationId = useSelector(state => state.chat.currentConversation);
  const conversations = useSelector(state => state.chat.conversations);
  const currentConversation = currentConversationId ? conversations[currentConversationId] : null;
  const currentAgentId = useSelector(state => state.chat.currentAgent);
  const agents = useSelector(state => state.chat.agents);
  const currentAgent = agents.find(a => a.id === currentAgentId);
  const userProfile = useSelector(state => state.chat.userProfile);
  const streamingMessage = useSelector(state => state.chat.streamingMessage);
  const editingMessageIndex = useSelector(state => state.chat.editingMessageIndex);
  const memories = useSelector(state => state.memories?.items || []);
  const stickyNotes = useSelector(state => state.stickyNotes.items);
  const chatContainerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [showStickyNotes, setShowStickyNotes] = useState(false);
  const isMobile = useIsMobile();
  const [localMessages, setLocalMessages] = useState([]);
  const [currentStreamingMessage, setCurrentStreamingMessage] = useState(null);

  useEffect(() => {
    if (user && !dataFetched) {
      console.log("Current Firebase User ID:", user.uid);
      dispatch(fetchUserData(user.uid)).then(() => setDataFetched(true));
      dispatch(fetchMemories(user.uid));
      dispatch(fetchStickyNotes(user.uid));
    }
  }, [dispatch, user, dataFetched]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [localMessages, streamingMessage]);

  useEffect(() => {
    if (streamingMessage) {
      setCurrentStreamingMessage(streamingMessage);
    }
  }, [streamingMessage]);

  useEffect(() => {
    if (currentConversation) {
      setLocalMessages(currentConversation.messages || []);
      setCurrentStreamingMessage(null);
    } else {
      setLocalMessages([]);
      setCurrentStreamingMessage(null);
    }
  }, [currentConversation]);

  useEffect(() => {
    const createDefaultConversation = async () => {
      if (user && dataFetched && Object.keys(conversations).length === 0) {
        try {
          const action = await dispatch(createNewConversation(user.uid));
          if (createNewConversation.fulfilled.match(action)) {
            dispatch(setCurrentConversation(action.payload.id));
          }
        } catch (error) {
          console.error('Failed to create default conversation:', error);
        }
      }
    };

    createDefaultConversation();
  }, [user, dataFetched, conversations, dispatch]);

  const handleNewChat = async () => {
    if (user) {
      try {
        const action = await dispatch(createNewConversation(user.uid));
        if (createNewConversation.fulfilled.match(action)) {
          dispatch(setCurrentAgent(null));
          dispatch(setCurrentConversation(action.payload.id));
        }
      } catch (error) {
        console.error('Failed to create new conversation:', error);
      }
    } else {
      console.error('User not authenticated');
    }
  };

  const handleSendMessage = async (message, images) => {
    if (editingMessageIndex !== null) {
      handleEditMessage(editingMessageIndex, message);
    } else if (user && user.uid) {
      let imageUrls = [];
      if (images.length > 0) {
        imageUrls = await Promise.all(images.map(image => uploadImage(image)));
      }

      const userMessage = { 
        role: 'user', 
        content: message,
        images: imageUrls,
        timestamp: new Date().toISOString() 
      };

      setLocalMessages(prevMessages => [...prevMessages, userMessage]);
      
      setIsLoading(true);
      dispatch(clearStreamingMessage());
      setCurrentStreamingMessage(null);

      try {
        let targetConversationId = currentConversationId;
        if (!targetConversationId) {
          const newConversation = await dispatch(createNewConversation(user.uid)).unwrap();
          targetConversationId = newConversation.id;
          dispatch(setCurrentConversation(targetConversationId));
        }

        const systemInstructionWithMemories = `${currentAgent?.systemInstruction || ''}\n\nUser Memories:\n${memories.join('\n')}`;

        await dispatch(addMessageAndGenerateResponse({ 
          userId: user.uid, 
          conversationId: targetConversationId, 
          message: userMessage,
          systemInstruction: systemInstructionWithMemories,
          userProfile: {
            ...userProfile,
            id: user.uid
          }
        })).unwrap();

      } catch (error) {
        console.error('Failed to send message and get AI response:', error);
      } finally {
        setIsLoading(false);
        dispatch(clearStreamingMessage());
        setCurrentStreamingMessage(null);
      }
    } else {
      console.error('User not authenticated or user ID is missing');
    }
  };

  const handleEditMessage = async (index, newContent) => {
    if (user && user.uid && currentConversationId) {
      try {
        await dispatch(editMessage({
          userId: user.uid,
          conversationId: currentConversationId,
          messageIndex: index,
          newContent
        })).unwrap();

        setLocalMessages(prevMessages => {
          const updatedMessages = [...prevMessages];
          updatedMessages[index] = { ...updatedMessages[index], content: newContent };
          return updatedMessages;
        });

        setIsLoading(true);
        dispatch(clearStreamingMessage());

        const systemInstructionWithMemories = `${currentAgent?.systemInstruction || ''}\n\nUser Memories:\n${memories.join('\n')}`;

        const response = await dispatch(addMessageAndGenerateResponse({ 
          userId: user.uid, 
          conversationId: currentConversationId, 
          message: {
            role: 'user',
            content: newContent,
            timestamp: new Date().toISOString()
          },
          systemInstruction: systemInstructionWithMemories,
          userProfile: {
            ...userProfile,
            id: user.uid
          }
        })).unwrap();

        // Add AI response to local messages
        setLocalMessages(prevMessages => [...prevMessages, {
          role: 'assistant',
          content: response.aiResponse.content,
          timestamp: response.aiResponse.timestamp
        }]);

      } catch (error) {
        console.error('Failed to edit message:', error);
      } finally {
        setIsLoading(false);
        dispatch(clearStreamingMessage());
        dispatch(setEditingMessageIndex(null));
      }
    } else {
      console.error('User not authenticated, user ID is missing, or conversation ID is missing');
    }
  };

  const handleMessageClick = (index) => {
    if (localMessages[index].role === 'user') {
      dispatch(setEditingMessageIndex(index));
    }
  };

  const cancelEdit = () => {
    dispatch(setEditingMessageIndex(null));
  };

  const handleAddStickyNote = () => {
    if (user) {
      dispatch(addStickyNote({ userId: user.uid, title: 'New Note', content: 'Add your content here' }));
    }
  };

  const handleUpdateStickyNote = (noteId, newTitle, newContent) => {
    if (user) {
      dispatch(updateStickyNote({ userId: user.uid, noteId, title: newTitle, content: newContent }));
    }
  };

  const handleDeleteStickyNote = (noteId) => {
    if (user) {
      dispatch(deleteStickyNote({ userId: user.uid, noteId }));
    }
  };

  const toggleStickyNotes = () => {
    setShowStickyNotes(!showStickyNotes);
  };

  const memoizedMessages = useMemo(() => {
    const messages = localMessages.map((msg, index) => ({
      ...msg,
      key: `${currentConversationId}-${index}`,
      user: msg.role === 'user' ? userProfile.name : currentAgent?.title || 'AI',
      aiProfilePicture: currentAgent?.profilePicture || DEFAULT_AI_PROFILE_IMAGE,
      imageSizeClass: 'w-18 h-18'
    }));

    if (currentStreamingMessage) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage && lastMessage.role === 'assistant') {
        messages[messages.length - 1] = {
          ...lastMessage,
          content: currentStreamingMessage
        };
      } else {
        messages.push({
          key: 'streaming',
          role: 'assistant',
          content: currentStreamingMessage,
          user: currentAgent?.title || 'AI',
          aiProfilePicture: currentAgent?.profilePicture || DEFAULT_AI_PROFILE_IMAGE,
          imageSizeClass: 'w-18 h-18'
        });
      }
    }

    return messages;
  }, [localMessages, currentConversationId, userProfile.name, currentAgent, currentStreamingMessage]);

  return (
    <div className="flex h-full relative">
      <BackgroundDesign />
      
      <div className={`flex-grow max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col h-full relative z-10 ${isMobile ? 'w-full' : ''}`}>
        {currentAgent && (
          <div className="sticky top-0 left-0 right-0 flex justify-center p-2 z-20 bg-opacity-50 bg-black">
            <div className="text-white px-4 py-1 rounded-full text-sm">
              {currentAgent.title}
            </div>
          </div>
        )}
        <div ref={chatContainerRef} className="flex-1 overflow-y-auto py-4">
          {memoizedMessages.map((msg) => (
            <ChatMessage
              key={msg.key}
              user={msg.user}
              message={msg.content}
              images={msg.images}
              ai={msg.role === 'assistant'}
              profilePicture={userProfile.imageUrl}
              aiProfilePicture={msg.aiProfilePicture}
              onMessageClick={() => handleMessageClick(msg.key === 'streaming' ? -1 : parseInt(msg.key.split('-')[1]))}
              isEditing={editingMessageIndex === parseInt(msg.key.split('-')[1])}
              imageSizeClass={msg.imageSizeClass}
            />
          ))}
        </div>
        <InputArea 
          onSendMessage={handleSendMessage} 
          isLoading={isLoading}
          onNewChat={handleNewChat}
          isEditing={editingMessageIndex !== null}
          editingMessage={editingMessageIndex !== null ? localMessages[editingMessageIndex].content : ''}
          onCancelEdit={cancelEdit}
        />
      </div>

      {/* Sticky Notes */}
      <div className={`absolute right-0 top-0 h-full w-64 transition-transform duration-300 z-30 ${showStickyNotes ? 'translate-x-0' : 'translate-x-full'} hidden lg:block`}>
        <div className="h-full bg-transparent flex flex-col p-4">
          <div className="flex items-center mb-4">
            <button onClick={handleAddStickyNote} className="flex-grow bg-blue-500 text-white py-2 px-4 rounded-l hover:bg-blue-600 transition-colors duration-200">
              + Add Note
            </button>
            <div className="bg-yellow-400 p-2 rounded-r">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
              </svg>
            </div>
          </div>
          <div className="flex-1 overflow-y-auto">
            {stickyNotes.map((note) => (
              <StickyNote
                key={note.id}
                note={note}
                onUpdate={handleUpdateStickyNote}
                onDelete={handleDeleteStickyNote}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Toggle Sticky Notes Button */}
      <button 
        onClick={toggleStickyNotes}
        className="absolute top-4 right-4 z-40 bg-yellow-400 text-gray-800 rounded-full p-2 shadow-lg hover:bg-yellow-500 transition-colors duration-200 hidden lg:block"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
        </svg>
      </button>
    </div>
  );
}

export default ChatArea;