import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { doc, getDoc, setDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../firebase';

export const fetchMemories = createAsyncThunk(
  'memories/fetchMemories',
  async (userId, { rejectWithValue }) => {
    try {
      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        return userDoc.data().memories || [];
      } else {
        // Create the user document if it doesn't exist
        await setDoc(userRef, { memories: [] });
        return [];
      }
    } catch (error) {
      console.error('Error fetching memories:', error);
      return rejectWithValue(error.message);
    }
  }
);

export const addMemory = createAsyncThunk(
  'memories/addMemory',
  async ({ userId, memory }, { rejectWithValue }) => {
    try {
      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);

      if (!userDoc.exists()) {
        await setDoc(userRef, { memories: [memory] });
      } else {
        await updateDoc(userRef, {
          memories: arrayUnion(memory)
        });
      }
      return memory;
    } catch (error) {
      console.error('Error adding memory:', error);
      return rejectWithValue(error.message);
    }
  }
);

export const updateMemory = createAsyncThunk(
  'memories/updateMemory',
  async ({ userId, index, updatedMemory }, { rejectWithValue }) => {
    try {
      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const memories = userDoc.data().memories || [];
        if (index >= 0 && index < memories.length) {
          memories[index] = updatedMemory;
          await updateDoc(userRef, { memories });
          return { index, updatedMemory };
        } else {
          throw new Error('Memory index out of range');
        }
      } else {
        throw new Error('User document does not exist');
      }
    } catch (error) {
      console.error('Error updating memory:', error);
      return rejectWithValue(error.message);
    }
  }
);

export const deleteMemory = createAsyncThunk(
  'memories/deleteMemory',
  async ({ userId, index }, { rejectWithValue }) => {
    try {
      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const memories = userDoc.data().memories || [];
        if (index >= 0 && index < memories.length) {
          memories.splice(index, 1);
          await updateDoc(userRef, { memories });
          return index;
        } else {
          throw new Error('Memory index out of range');
        }
      } else {
        throw new Error('User document does not exist');
      }
    } catch (error) {
      console.error('Error deleting memory:', error);
      return rejectWithValue(error.message);
    }
  }
);

export const deleteAllMemories = createAsyncThunk(
  'memories/deleteAllMemories',
  async (userId, { rejectWithValue }) => {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, { memories: [] });
      return [];
    } catch (error) {
      console.error('Error deleting all memories:', error);
      return rejectWithValue(error.message);
    }
  }
);

const memoriesSlice = createSlice({
  name: 'memories',
  initialState: {
    items: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMemories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMemories.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
        state.error = null;
      })
      .addCase(fetchMemories.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(addMemory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addMemory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items.push(action.payload);
        state.error = null;
      })
      .addCase(addMemory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateMemory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateMemory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const { index, updatedMemory } = action.payload;
        state.items[index] = updatedMemory;
        state.error = null;
      })
      .addCase(updateMemory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deleteMemory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteMemory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items.splice(action.payload, 1);
        state.error = null;
      })
      .addCase(deleteMemory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deleteAllMemories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteAllMemories.fulfilled, (state) => {
        state.status = 'succeeded';
        state.items = [];
        state.error = null;
      })
      .addCase(deleteAllMemories.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default memoriesSlice.reducer;