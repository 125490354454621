import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { db } from '../firebase';
import { collection, getDocs, addDoc, doc, updateDoc, deleteDoc } from 'firebase/firestore';

export const fetchStickyNotes = createAsyncThunk('stickyNotes/fetchStickyNotes', async (userId) => {
  const stickyNotesRef = collection(db, `users/${userId}/stickynotes`);
  const snapshot = await getDocs(stickyNotesRef);
  return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
});

export const addStickyNote = createAsyncThunk('stickyNotes/addStickyNote', async ({ userId, title, content }) => {
  const stickyNotesRef = collection(db, `users/${userId}/stickynotes`);
  const newNoteRef = await addDoc(stickyNotesRef, { title, content });
  return { id: newNoteRef.id, title, content };
});

export const updateStickyNote = createAsyncThunk('stickyNotes/updateStickyNote', async ({ userId, noteId, title, content }) => {
  const noteRef = doc(db, `users/${userId}/stickynotes/${noteId}`);
  await updateDoc(noteRef, { title, content });
  return { id: noteId, title, content };
});

export const deleteStickyNote = createAsyncThunk('stickyNotes/deleteStickyNote', async ({ userId, noteId }) => {
  const noteRef = doc(db, `users/${userId}/stickynotes/${noteId}`);
  await deleteDoc(noteRef);
  return noteId;
});

const stickyNotesSlice = createSlice({
  name: 'stickyNotes',
  initialState: {
    items: [],
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStickyNotes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStickyNotes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
      })
      .addCase(fetchStickyNotes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addStickyNote.fulfilled, (state, action) => {
        state.items.push(action.payload);
      })
      .addCase(updateStickyNote.fulfilled, (state, action) => {
        const index = state.items.findIndex(note => note.id === action.payload.id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      })
      .addCase(deleteStickyNote.fulfilled, (state, action) => {
        state.items = state.items.filter(note => note.id !== action.payload);
      });
  }
});

export default stickyNotesSlice.reducer;