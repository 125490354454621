import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserProfile, deleteAllConversations } from './redux/chatSlice';
import { AuthContext } from './AuthContext';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from './firebase';
import { ChevronLeftIcon } from '@heroicons/react/solid';
import MemoriesManager from './MemoriesManager';

function UserProfileSettings({ onClose }) {
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);
  const userProfile = useSelector(state => state.chat.userProfile);
  
  const [name, setName] = useState(userProfile.name || '');
  const [imageUrl, setImageUrl] = useState(userProfile.imageUrl || '');
  const [information, setInformation] = useState(userProfile.information || '');
  const [customInstruction, setCustomInstruction] = useState(userProfile.customInstruction || '');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const [showMemoriesManager, setShowMemoriesManager] = useState(false);

  useEffect(() => {
    setName(userProfile.name || '');
    setImageUrl(userProfile.imageUrl || '');
    setInformation(userProfile.information || '');
    setCustomInstruction(userProfile.customInstruction || '');
  }, [userProfile]);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const storageRef = ref(storage, `profileImages/${user.uid}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      setImageUrl(downloadURL);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedProfile = { name, imageUrl, information, customInstruction };
    await dispatch(updateUserProfile({ userId: user.uid, profileData: updatedProfile }));
    onClose();
  };

  const handleDeleteAllConversations = async () => {
    if (user) {
      setIsDeleting(true);
      setDeleteError(null);
      try {
        await dispatch(deleteAllConversations(user.uid)).unwrap();
        setShowDeleteConfirmation(false);
      } catch (error) {
        setDeleteError("Failed to delete conversations. Please try again.");
      } finally {
        setIsDeleting(false);
      }
    }
  };

  return (
    <div className="flex flex-col h-full text-sm">
      <div className="p-2 border-b border-gray-700 flex items-center">
        <button
          onClick={onClose}
          className="text-gray-400 hover:text-white transition-colors duration-200"
        >
          <ChevronLeftIcon className="h-6 w-6" />
        </button>
        <h2 className="text-lg font-semibold ml-2">User Settings</h2>
      </div>


      <form onSubmit={handleSubmit} className="flex-grow overflow-y-auto px-4 pt-2 space-y-2">
        <div>
          <label htmlFor="name" className="block text-xs font-medium text-gray-300">Your Name</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="e.g., John"
            className="mt-1 block w-full rounded-md bg-gray-700 border-gray-600 text-white text-sm p-1"
          />
        </div>

        <div>
          <label htmlFor="profileImage" className="block text-xs font-medium text-gray-300">Profile Image</label>
          <div className="flex items-center mt-1">
            <input
              type="file"
              id="profileImage"
              onChange={handleImageUpload}
              accept="image/*"
              className="block w-full text-xs text-gray-300
                        file:mr-2 file:py-1 file:px-2
                        file:rounded-md file:border-0
                        file:text-xs file:font-semibold
                        file:bg-gray-700 file:text-white
                        hover:file:bg-gray-600"
            />
            {imageUrl && <img src={imageUrl} alt="Profile" className="ml-2 w-8 h-8 rounded-full" />}
          </div>
        </div>

        <div>
          <label htmlFor="information" className="block text-xs font-medium text-gray-300">Your Information</label>
          <textarea
            id="information"
            value={information}
            onChange={(e) => setInformation(e.target.value)}
            placeholder="e.g., I am a sales manager, I live in New York, I love playing guitar..."
            rows="3"
            className="mt-1 block w-full rounded-md bg-gray-700 border-gray-600 text-white text-sm p-1"
          ></textarea>
        </div>

        <div>
          <label htmlFor="customInstruction" className="block text-xs font-medium text-gray-300">Custom Instruction</label>
            <textarea
              id="customInstruction"
              value={customInstruction}
              onChange={(e) => setCustomInstruction(e.target.value)}
              placeholder="e.g., Respond casually. Use professional tone. Use short responses..."
              rows="3"
              className="mt-1 block w-full rounded-md bg-gray-700 border-gray-600 text-white text-sm p-1"
            ></textarea>
        </div>

        <div className="pt-2">
          <button 
            type="button"
            onClick={() => setShowMemoriesManager(true)}
            className="w-full py-2 px-3 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Manage Memories
          </button>
        </div>

        <div className="pt-2">
          <button 
            type="submit" 
            className="w-full py-2 px-3 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save Profile
          </button>
        </div>

        <div className="pt-2">
          <button 
            type="button"
            onClick={() => setShowDeleteConfirmation(true)}
            className="w-full py-2 px-3 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Delete All Conversations
          </button>
        </div>

        {deleteError && (
          <div className="text-red-500 text-xs mt-2">
            {deleteError}
          </div>
        )}
      </form>

      {showDeleteConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-800 p-6 rounded-lg max-w-sm w-full">
            <h3 className="text-xl font-bold mb-4">Delete All Conversations</h3>
            <p className="mb-6">Are you sure you want to delete all conversations? This action cannot be undone.</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowDeleteConfirmation(false)}
                className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 transition-colors"
                disabled={isDeleting}
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteAllConversations}
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
                disabled={isDeleting}
              >
                {isDeleting ? 'Deleting...' : 'Delete All'}
              </button>
            </div>
          </div>
        </div>
      )}

      {showMemoriesManager && (
        <MemoriesManager onClose={() => setShowMemoriesManager(false)} userId={user.uid} />
      )}
    </div>
  );
}

export default UserProfileSettings;
