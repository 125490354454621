// firebase.js

import { initializeApp } from 'firebase/app';
import { getAuth, signInAnonymously, onAuthStateChanged } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyAyapdyISmrkR1OipqZSG8nBFa-8adsB0g",
  authDomain: "worify.firebaseapp.com",
  projectId: "worify",
  storageBucket: "worify.appspot.com",
  messagingSenderId: "324228562272",
  appId: "1:324228562272:web:146b2d3343003fc1f07205",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);

signInAnonymously(auth)
  .then(() => {
    console.log('Signed in anonymously');
  })
  .catch((error) => {
    console.error('Anonymous sign-in failed:', error);
  });

const uploadImage = async (file) => {
  const storageRef = ref(storage, 'images/' + file.name);
  await uploadBytes(storageRef, file);
  return getDownloadURL(storageRef);
};

export { auth, db, storage, functions, uploadImage };

// Optional: handle user state changes
onAuthStateChanged(auth, (user) => {
  if (user) {
    console.log('User is signed in:', user.uid);
  } else {
    console.log('User is signed out');
  }
});
