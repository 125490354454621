import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { db, storage } from '../firebase';
import {
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  getDoc,
  getDocs,
  doc,
  setDoc,
  arrayUnion,
  arrayRemove,
  onSnapshot,
  query,
  where,
  collectionGroup
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';

// Fetch tasks for a specific user or where the user is a collaborator
const fetchTasks = createAsyncThunk(
  'tasks/fetchTasks',
  async (userId, { dispatch }) => {
    try {
      console.log('Fetching tasks for user:', userId);
      
      // Fetch user's own tasks
      const userTasksRef = collection(db, `users/${userId}/tasks`);
      const userTasksQuery = query(userTasksRef);
      const querySnapshot = await getDocs(userTasksQuery);
      const tasks = querySnapshot.docs.map((doc) => ({ 
        id: doc.id, 
        ...doc.data(), 
        taskCreatorId: userId,
        subtasks: doc.data().subtasks || []
      }));
      console.log('Fetched user tasks:', tasks);

      // Set up real-time listener for user's tasks
      onSnapshot(userTasksQuery, (snapshot) => {
        const updatedTasks = snapshot.docs.map((doc) => {
          const taskData = doc.data();
          return { 
            id: doc.id, 
            ...taskData, 
            taskCreatorId: userId,
            subtasks: taskData.subtasks || []
          };
        });
        dispatch(setTasks(updatedTasks));
      });

      // Fetch tasks where user is a collaborator
      const allTasksRef = collectionGroup(db, 'tasks');
      const collaboratorTasksQuery = query(allTasksRef, where('collaborators', 'array-contains', userId));
      const collaboratorSnapshot = await getDocs(collaboratorTasksQuery);
      const collaboratorTasks = collaboratorSnapshot.docs.map((doc) => {
        const data = doc.data();
        const [, taskCreatorId] = doc.ref.path.split('/');
        return { 
          id: doc.id, 
          ...data, 
          taskCreatorId,
          subtasks: data.subtasks || []
        };
      });
      console.log('Fetched collaborator tasks:', collaboratorTasks);

      // Set up real-time listener for collaborator tasks
      onSnapshot(collaboratorTasksQuery, (snapshot) => {
        const updatedCollaboratorTasks = snapshot.docs.map((doc) => {
          const data = doc.data();
          const [, taskCreatorId] = doc.ref.path.split('/');
          return { 
            id: doc.id, 
            ...data, 
            taskCreatorId,
            subtasks: data.subtasks || []
          };
        });
        dispatch(setCollaboratorTasks(updatedCollaboratorTasks));
      });

      return [...tasks, ...collaboratorTasks];
    } catch (error) {
      console.error('Error fetching tasks:', error);
      throw error;
    }
  }
);

// Create a new task for a user
 const createTask = createAsyncThunk(
  'tasks/createTask',
  async ({ userId, taskData }, { rejectWithValue }) => {
    try {
      if (!userId) {
        return rejectWithValue('User ID is required to create a task');
      }
      const tasksRef = collection(db, `users/${userId}/tasks`);
      const newTaskData = {
        ...taskData,
        createdBy: userId,
        createdAt: new Date().toISOString(),
        status: taskData.status || 'todo',
        notes: taskData.notes || '',
        attachments: [],
        dueDate: taskData.dueDate || null,
        tags: taskData.tags || [],
        priority: taskData.priority || 'normal',
        dailyNotes: {},
        subtasks: taskData.subtasks || [],
        collaborators: [userId],
        assignedTo: userId
      };
      const docRef = await addDoc(tasksRef, newTaskData);
      return { id: docRef.id, ...newTaskData, taskCreatorId: userId };
    } catch (error) {
      return rejectWithValue(error.message || 'An unexpected error occurred while creating the task');
    }
  }
);

const updateTask = createAsyncThunk(
  'tasks/updateTask',
  async ({ firebaseUserId, taskId, taskData, taskCreatorId }, { rejectWithValue }) => {
    try {
      console.log('updateTask called with:', { firebaseUserId, taskId, taskData, taskCreatorId });

      if (!taskId) {
        console.error('Task ID is missing');
        return rejectWithValue('Task ID is required to update a task');
      }

      if (!firebaseUserId) {
        console.error('Firebase User ID is missing');
        return rejectWithValue('Firebase User ID is required to update a task');
      }

      console.log('Updating task:', { firebaseUserId, taskId, taskData, taskCreatorId });

      // Try to find the task in all possible locations
      const possibleLocations = [taskCreatorId, firebaseUserId].filter(Boolean);
      let taskRef;
      let taskSnapshot;

      for (const userId of possibleLocations) {
        console.log(`Checking for task in user ${userId}'s collection`);
        taskRef = doc(db, `users/${userId}/tasks`, taskId);
        taskSnapshot = await getDoc(taskRef);
        if (taskSnapshot.exists()) {
          console.log(`Task found in user ${userId}'s collection`);
          break;
        }
      }

      // If still not found, search in all users' task collections
      if (!taskSnapshot || !taskSnapshot.exists()) {
        console.log('Task not found in expected locations, searching all collections');
        const allTasksQuery = query(
          collectionGroup(db, 'tasks'),
          where('id', '==', taskId)
        );
        
        const querySnapshot = await getDocs(allTasksQuery);
        
        if (querySnapshot.empty) {
          console.error('Task not found in any collection');
          return rejectWithValue('Task not found');
        }
        
        // Use the first matching document (there should only be one)
        taskRef = querySnapshot.docs[0].ref;
        taskSnapshot = querySnapshot.docs[0];
        console.log('Task found in collection:', taskRef.path);
      }

      console.log('Updating task with data:', taskData);
      // Update the task
      await updateDoc(taskRef, taskData);

      const updatedTaskSnapshot = await getDoc(taskRef);
      const fullTaskData = updatedTaskSnapshot.data();

      console.log('Task updated successfully:', fullTaskData);
      return { 
        id: taskId, 
        ...fullTaskData, 
        taskCreatorId: fullTaskData.createdBy || taskCreatorId || firebaseUserId 
      };
    } catch (error) {
      console.error('Error updating task:', error);
      return rejectWithValue(error.message || 'An unexpected error occurred while updating the task');
    }
  }
);

const updateSubtaskNotes = createAsyncThunk(
  'tasks/updateSubtaskNotes',
  async ({ userId, taskId, updateData, taskCreatorId }, { rejectWithValue }) => {
    try {
      console.log('Updating subtask notes:', taskId);
      console.log('Update data:', updateData);

      if (!taskId) {
        return rejectWithValue('Task ID is required to update subtask notes');
      }
      const taskRef = doc(db, `users/${taskCreatorId || userId}/tasks`, taskId);
      
      const taskSnapshot = await getDoc(taskRef);
      const currentTask = taskSnapshot.data();
      
      if (!currentTask.subtasks || !Array.isArray(currentTask.subtasks)) {
        return rejectWithValue('Task does not have subtasks array');
      }

      const updatedSubtasks = [...currentTask.subtasks];
      if (!updatedSubtasks[updateData.subtaskIndex]) {
        return rejectWithValue('Subtask index out of bounds');
      }

      if (!updatedSubtasks[updateData.subtaskIndex].dailyNotes) {
        updatedSubtasks[updateData.subtaskIndex].dailyNotes = {};
      }

      updatedSubtasks[updateData.subtaskIndex].dailyNotes[updateData.dateString] = updateData.notes;

      await updateDoc(taskRef, { subtasks: updatedSubtasks });

      const updatedTaskSnapshot = await getDoc(taskRef);
      const fullTaskData = updatedTaskSnapshot.data();

      console.log('Subtask notes updated successfully:', fullTaskData);
      return { id: taskId, ...fullTaskData, taskCreatorId: taskCreatorId || userId };
    } catch (error) {
      console.error('Error updating subtask notes:', error);
      return rejectWithValue(error.message || 'An unexpected error occurred while updating subtask notes');
    }
  }
);

 const deleteTask = createAsyncThunk(
  'tasks/deleteTask',
  async ({ userId, taskId, taskCreatorId }, { rejectWithValue }) => {
    try {
      if (!taskId) {
        return rejectWithValue('Task ID is required to delete a task');
      }
      const taskRef = doc(db, `users/${taskCreatorId || userId}/tasks`, taskId);

      const taskSnapshot = await getDoc(taskRef);
      const taskData = taskSnapshot.data();
      if (taskData && taskData.attachments) {
        await Promise.all(taskData.attachments.map(async attachment => {
          const attachmentRef = ref(storage, attachment.path);
          await deleteObject(attachmentRef);
        }));
      }

      await deleteDoc(taskRef);
      return taskId;
    } catch (error) {
      return rejectWithValue(error.message || 'An unexpected error occurred while deleting the task');
    }
  }
);

// Add an attachment to a task
 const addTaskAttachment = createAsyncThunk(
  'tasks/addTaskAttachment',
  async ({ userId, taskId, file, taskCreatorId }, { rejectWithValue }) => {
    try {
      if (!taskId || !file) {
        return rejectWithValue('Task ID and file are required to add an attachment');
      }
      const filePath = `taskAttachments/${taskCreatorId || userId}/${taskId}/${file.name}`;
      const storageRef = ref(storage, filePath);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);

      const taskRef = doc(db, `users/${taskCreatorId || userId}/tasks`, taskId);
      const newAttachment = {
        name: file.name,
        url: downloadURL,
        type: file.type,
        path: filePath,
        uploadedAt: new Date().toISOString()
      };

      await updateDoc(taskRef, {
        attachments: arrayUnion(newAttachment)
      });

      return { taskId, attachment: newAttachment };
    } catch (error) {
      return rejectWithValue(error.message || 'An unexpected error occurred while adding the attachment');
    }
  }
);

// Remove an attachment from a task
 const removeTaskAttachment = createAsyncThunk(
  'tasks/removeTaskAttachment',
  async ({ userId, taskId, attachment, taskCreatorId }, { rejectWithValue }) => {
    try {
      if (!taskId || !attachment) {
        return rejectWithValue('Task ID and attachment are required to remove an attachment');
      }
      const taskRef = doc(db, `users/${taskCreatorId || userId}/tasks`, taskId);

      const attachmentRef = ref(storage, attachment.path);
      await deleteObject(attachmentRef);

      await updateDoc(taskRef, {
        attachments: arrayRemove(attachment)
      });

      return { taskId, attachmentPath: attachment.path };
    } catch (error) {
      return rejectWithValue(error.message || 'An unexpected error occurred while removing the attachment');
    }
  }
);

// Update daily notes for a task
 const updateDailyNotes = createAsyncThunk(
  'tasks/updateDailyNotes',
  async ({ userId, taskId, date, notes, isSubtask, subtaskIndex, taskCreatorId }, { rejectWithValue }) => {
    try {
      if (!taskId) {
        return rejectWithValue('Task ID is required to update daily notes');
      }
      const taskRef = doc(db, `users/${taskCreatorId || userId}/tasks`, taskId);

      const taskSnapshot = await getDoc(taskRef);
      const taskData = taskSnapshot.data();

      let updatedField;
      if (isSubtask && subtaskIndex !== null) {
        updatedField = {
          subtasks: taskData.subtasks.map((subtask, index) => (
            index === subtaskIndex
              ? { ...subtask, dailyNotes: { ...subtask.dailyNotes, [date]: notes } }
              : subtask
          ))
        };
      } else {
        updatedField = {
          dailyNotes: {
            ...taskData.dailyNotes,
            [date]: notes
          }
        };
      }

      await updateDoc(taskRef, updatedField);
      return { taskId, date, notes, isSubtask, subtaskIndex };
    } catch (error) {
      return rejectWithValue(error.message || 'An unexpected error occurred while updating daily notes');
    }
  }
);

// Create a new subtask
 const createSubtask = createAsyncThunk(
  'tasks/createSubtask',
  async ({ userId, taskId, subtaskData, taskCreatorId }, { getState, dispatch }) => {
    try {
      const taskRef = doc(db, `users/${taskCreatorId || userId}/tasks`, taskId);
      const taskSnapshot = await getDoc(taskRef);
      const currentTask = taskSnapshot.data();

      const updatedSubtasks = [...(currentTask.subtasks || []), subtaskData];

      await updateDoc(taskRef, { subtasks: updatedSubtasks });

      // Fetch the updated task to ensure we have the latest data
      const updatedTaskSnapshot = await getDoc(taskRef);
      const updatedTask = { id: taskId, ...updatedTaskSnapshot.data(), taskCreatorId: taskCreatorId || userId };

      // Update the task in the Redux store
      dispatch(updateTaskInState(updatedTask));

      return updatedTask;
    } catch (error) {
      console.error('Error creating subtask:', error);
      throw error;
    }
  }
);

// Action to update task from listener
 const updateTaskFromListener = createAction('tasks/updateTaskFromListener');

// Task slice with reducers
const taskSlice = createSlice({
  name: 'tasks',
  initialState: {
    tasks: [],
    collaboratorTasks: [],
    status: 'idle',
    error: null
  },
  reducers: {
    clearTaskError: (state) => {
      state.error = null;
    },
    setTasks: (state, action) => {
      state.tasks = action.payload;
    },
    setCollaboratorTasks: (state, action) => {
      state.collaboratorTasks = action.payload;
    },
    updateTaskInState: (state, action) => {
      const updateTaskInArray = (taskArray) => {
        const index = taskArray.findIndex(task => task.id === action.payload.id);
        if (index !== -1) {
          return [
            ...taskArray.slice(0, index),
            { ...taskArray[index], ...action.payload },
            ...taskArray.slice(index + 1)
          ];
        }
        return taskArray;
      };
    
      state.tasks = updateTaskInArray(state.tasks);
      state.collaboratorTasks = updateTaskInArray(state.collaboratorTasks);
    },
    [updateTaskFromListener]: (state, action) => {
      const index = state.tasks.findIndex(task => task.id === action.payload.id);
      if (index !== -1) {
        state.tasks[index] = action.payload;
      } else {
        const collaboratorIndex = state.collaboratorTasks.findIndex(task => task.id === action.payload.id);
        if (collaboratorIndex !== -1) {
          state.collaboratorTasks[collaboratorIndex] = action.payload;
        } else {
          state.collaboratorTasks.push(action.payload);
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTasks.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchTasks.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.tasks = action.payload.filter(task => task.createdBy === action.meta.arg);
        state.collaboratorTasks = action.payload.filter(task => task.createdBy !== action.meta.arg);
        state.error = null;
      })
      .addCase(fetchTasks.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createTask.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(createTask.fulfilled, (state, action) => {
        state.tasks.push(action.payload);
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(createTask.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateTask.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(updateTask.fulfilled, (state, action) => {
        const updateTaskInArray = (taskArray) => {
          const index = taskArray.findIndex(task => task.id === action.payload.id);
          if (index !== -1) {
            return [
              ...taskArray.slice(0, index),
              { ...taskArray[index], ...action.payload },
              ...taskArray.slice(index + 1)
            ];
          }
          return taskArray;
        };

        state.tasks = updateTaskInArray(state.tasks);
        state.collaboratorTasks = updateTaskInArray(state.collaboratorTasks);
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(updateTask.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deleteTask.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(deleteTask.fulfilled, (state, action) => {
        state.tasks = state.tasks.filter((task) => task.id !== action.payload);
        state.collaboratorTasks = state.collaboratorTasks.filter((task) => task.id !== action.payload);
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(deleteTask.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateSubtaskNotes.fulfilled, (state, action) => {
        const index = state.tasks.findIndex(task => task.id === action.payload.id);
        if (index !== -1) {
          state.tasks[index] = action.payload;
        } else {
          const collaboratorIndex = state.collaboratorTasks.findIndex(task => task.id === action.payload.id);
          if (collaboratorIndex !== -1) {
            state.collaboratorTasks[collaboratorIndex] = action.payload;
          }
        }
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(addTaskAttachment.fulfilled, (state, action) => {
        const taskIndex = state.tasks.findIndex((t) => t.id === action.payload.taskId);
        if (taskIndex !== -1) {
          const task = { ...state.tasks[taskIndex] };
          task.attachments = [...(task.attachments || []), action.payload.attachment];
          state.tasks[taskIndex] = task;
        } else {
          const collaboratorTaskIndex = state.collaboratorTasks.findIndex((t) => t.id === action.payload.taskId);
          if (collaboratorTaskIndex !== -1) {
            const task = { ...state.collaboratorTasks[collaboratorTaskIndex] };
            task.attachments = [...(task.attachments || []), action.payload.attachment];
            state.collaboratorTasks[collaboratorTaskIndex] = task;
          }
        }
      })
      .addCase(removeTaskAttachment.fulfilled, (state, action) => {
        const taskIndex = state.tasks.findIndex((t) => t.id === action.payload.taskId);
        if (taskIndex !== -1) {
          const task = { ...state.tasks[taskIndex] };
          task.attachments = task.attachments.filter((a) => a.path !== action.payload.attachmentPath);
          state.tasks[taskIndex] = task;
        } else {
          const collaboratorTaskIndex = state.collaboratorTasks.findIndex((t) => t.id === action.payload.taskId);
          if (collaboratorTaskIndex !== -1) {
            const task = { ...state.collaboratorTasks[collaboratorTaskIndex] };
            task.attachments = task.attachments.filter((a) => a.path !== action.payload.attachmentPath);
            state.collaboratorTasks[collaboratorTaskIndex] = task;
          }
        }
      })
      .addCase(updateDailyNotes.fulfilled, (state, action) => {
        const updateTaskNotes = (taskArray) => {
          const taskIndex = taskArray.findIndex((t) => t.id === action.payload.taskId);
          if (taskIndex !== -1) {
            const task = { ...taskArray[taskIndex] };
            if (action.payload.isSubtask) {
              const subtaskIndex = action.payload.subtaskIndex;
              const subtask = { ...task.subtasks[subtaskIndex] };
              subtask.dailyNotes = { ...subtask.dailyNotes, [action.payload.date]: action.payload.notes };
              task.subtasks = [
                ...task.subtasks.slice(0, subtaskIndex),
                subtask,
                ...task.subtasks.slice(subtaskIndex + 1)
              ];
            } else {
              task.dailyNotes = { ...task.dailyNotes, [action.payload.date]: action.payload.notes };
            }
            return [...taskArray.slice(0, taskIndex), task, ...taskArray.slice(taskIndex + 1)];
          }
          return taskArray;
        };

        state.tasks = updateTaskNotes(state.tasks);
        state.collaboratorTasks = updateTaskNotes(state.collaboratorTasks);
      })
      .addCase(createSubtask.fulfilled, (state, action) => {
        const updateTaskInArray = (taskArray) => {
          const index = taskArray.findIndex(task => task.id === action.payload.id);
          if (index !== -1) {
            return [
              ...taskArray.slice(0, index),
              { ...taskArray[index], subtasks: action.payload.subtasks },
              ...taskArray.slice(index + 1)
            ];
          }
          return taskArray;
        };

        state.tasks = updateTaskInArray(state.tasks);
        state.collaboratorTasks = updateTaskInArray(state.collaboratorTasks);
        state.status = 'succeeded';
        state.error = null;
      });
  }
});

const { 
  clearTaskError, 
  setTasks, 
  setCollaboratorTasks, 
  updateTaskInState 
} = taskSlice.actions;

// Single export statement
export {
  taskSlice,
  clearTaskError,
  setTasks,
  setCollaboratorTasks,
  updateTaskInState,
  fetchTasks,
  createTask,
  updateTask,
  deleteTask,
  addTaskAttachment,
  removeTaskAttachment,
  updateDailyNotes,
  createSubtask,
  updateSubtaskNotes,
  updateTaskFromListener
};

// Export the reducer as default
export default taskSlice.reducer;
