import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTasks, updateTask, createTask, deleteTask, updateTaskFromListener, updateSubtaskNotes } from './redux/taskSlice';
import { fetchAllUsers } from './redux/userSlice';
import { auth, db } from './firebase';
import {
  PlusIcon,
  SearchIcon,
  XIcon,
  MenuIcon,
  TrashIcon,
  ChevronDownIcon,
  ChevronRightIcon as ChevronRightIconSolid,
  UserAddIcon,
} from '@heroicons/react/outline';
import { onSnapshot, doc } from 'firebase/firestore';
import Header from './Header';

const Notebook = ({ cycleDesign, currentDesign, onClose }) => {
  const dispatch = useDispatch();
  const tasks = useSelector((state) => [...state.tasks.tasks, ...state.tasks.collaboratorTasks]);
  const users = useSelector((state) => state.user.users);
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const firebaseUser = auth.currentUser;

  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedSubtask, setSelectedSubtask] = useState(null);
  const [notes, setNotes] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [showNewNoteModal, setShowNewNoteModal] = useState(false);
  const [newNoteTitle, setNewNoteTitle] = useState('');
  const [showSidebar, setShowSidebar] = useState(true);
  const [expandedTasks, setExpandedTasks] = useState([]);
  const [showCollaboratorModal, setShowCollaboratorModal] = useState(false);
  const [collaboratorSearch, setCollaboratorSearch] = useState('');

  useEffect(() => {
    if (firebaseUser) {
      dispatch(fetchTasks(firebaseUser.uid))
        .unwrap()
        .then(() => console.log('Tasks fetched successfully'))
        .catch((error) => console.error('Failed to fetch tasks:', error));
    }
    if (isAdmin) {
      dispatch(fetchAllUsers());
    }
  }, [dispatch, firebaseUser, isAdmin]);

  useEffect(() => {
    let unsubscribe;
  
    if (selectedTask) {
      const taskRef = doc(db, `users/${selectedTask.taskCreatorId}/tasks`, selectedTask.id);
      unsubscribe = onSnapshot(taskRef, (doc) => {
        if (doc.exists()) {
          const updatedTask = { id: doc.id, ...doc.data(), taskCreatorId: selectedTask.taskCreatorId };
          dispatch(updateTaskFromListener(updatedTask));
          
          if (selectedTask.id === updatedTask.id) {
            if (selectedSubtask) {
              const subtasks = Array.isArray(updatedTask.subtasks) ? updatedTask.subtasks : [];
              const updatedSubtask = subtasks.find(
                subtask => subtask.title === selectedSubtask.title
              );
              if (updatedSubtask) {
                setNotes(updatedSubtask.notes || '');
              }
            } else {
              setNotes(updatedTask.notes || '');
            }
          }
        }
      });
    }
  
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [dispatch, selectedTask, selectedSubtask]);

  const updateNotes = useCallback((item) => {
    setNotes(item.notes || '');
  }, []);

  useEffect(() => {
    if (selectedTask) {
      if (selectedSubtask) {
        updateNotes(selectedSubtask);
      } else {
        updateNotes(selectedTask);
      }
    } else {
      setNotes('');
    }
  }, [selectedTask, selectedSubtask, updateNotes]);

  const filteredTasks = useMemo(() => {
    const uniqueTasks = {};
    tasks.forEach(task => {
      if (
        (task.list === 'everyday' || task.list === 'independent') &&
        (task.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
         (task.notes && task.notes.toLowerCase().includes(searchTerm.toLowerCase())) ||
         task.subtasks?.some(subtask => 
           subtask.notes && subtask.notes.toLowerCase().includes(searchTerm.toLowerCase())
         ))
      ) {
        uniqueTasks[task.id] = task;
      }
    });
    return Object.values(uniqueTasks);
  }, [tasks, searchTerm]);

  const handleSaveNotes = useCallback(() => {
    if (firebaseUser && selectedTask) {
      console.log('Saving notes for:', selectedTask.title);
      console.log('Selected subtask:', selectedSubtask?.title);
      
      const taskId = selectedTask.id;
      const isSubtask = Boolean(selectedSubtask);
  
      if (isSubtask && selectedSubtask) {
        console.log('Updating subtask notes');
        const subtaskIndex = selectedTask.subtasks.findIndex(subtask => subtask.title === selectedSubtask.title);
        if (subtaskIndex === -1) {
          console.error('Selected subtask not found');
          return;
        }
  
        const updateData = {
          subtaskIndex,
          notes
        };
  
        console.log('Updating subtask with data:', updateData);
        dispatch(updateSubtaskNotes({
          userId: firebaseUser.uid,
          taskId: taskId,
          updateData,
          taskCreatorId: selectedTask.taskCreatorId
        }));
      } else {
        console.log('Updating main task notes');
        const updateData = {
          notes: notes
        };
  
        console.log('Updating task with data:', updateData);
        dispatch(updateTask({
          firebaseUserId: firebaseUser.uid,
          userId: firebaseUser.uid,
          taskId: taskId,
          taskData: updateData,
          taskCreatorId: selectedTask.taskCreatorId
        }));
      }
    } else {
      console.error('Cannot save notes: firebaseUser or selectedTask is missing');
    }
  }, [notes, selectedTask, selectedSubtask, dispatch, firebaseUser]);

  const handleCreateNewNote = useCallback(() => {
    if (firebaseUser && newNoteTitle.trim()) {
      dispatch(
        createTask({
          userId: firebaseUser.uid,
          taskData: {
            title: newNoteTitle,
            list: 'independent',
            status: 'ongoing',
            collaborators: [firebaseUser.uid],
          },
        })
      ).then(() => {
        setNewNoteTitle('');
        setShowNewNoteModal(false);
        dispatch(fetchTasks(firebaseUser.uid));
      });
    }
  }, [firebaseUser, newNoteTitle, dispatch]);

  const handleDeleteNote = useCallback(
    (note) => {
      if (firebaseUser && note) {
        if (window.confirm('Are you sure you want to delete this note?')) {
          dispatch(
            deleteTask({
              userId: firebaseUser.uid,
              taskId: note.id,
              taskCreatorId: note.taskCreatorId
            })
          ).then(() => {
            if (selectedTask && selectedTask.id === note.id) {
              setSelectedTask(null);
            }
            dispatch(fetchTasks(firebaseUser.uid));
          });
        }
      }
    },
    [firebaseUser, selectedTask, dispatch]
  );

  const handleTaskClick = (task) => {
    if (selectedTask && selectedTask.id === task.id) {
      setSelectedTask(null);
      setSelectedSubtask(null);
    } else {
      setSelectedTask(task);
      setSelectedSubtask(null);
      updateNotes(task);
    }
  };

  const handleSubtaskClick = (subtask) => {
    setSelectedSubtask(subtask);
    if (subtask) {
      setNotes(subtask.notes || '');
    }
  };

  const toggleTaskExpansion = (taskId) => {
    setExpandedTasks((prevExpandedTasks) =>
      prevExpandedTasks.includes(taskId)
        ? prevExpandedTasks.filter((id) => id !== taskId)
        : [...prevExpandedTasks, taskId]
    );
  };

  const isTaskExpanded = (taskId) => expandedTasks.includes(taskId);

  const handleNewChatClick = () => {
    console.log('Navigating to new chat area...');
  };

  const handleAddCollaborator = (userId) => {
    if (selectedTask && !selectedTask.collaborators?.includes(userId) && firebaseUser) {
      const updatedCollaborators = [...(selectedTask.collaborators || []), userId];
      
      dispatch(updateTask({
        firebaseUserId: firebaseUser.uid,
        taskId: selectedTask.id,
        taskData: { collaborators: updatedCollaborators },
        taskCreatorId: selectedTask.taskCreatorId || selectedTask.createdBy
      }))
      .then(() => {
        setSelectedTask(prevTask => ({
          ...prevTask,
          collaborators: updatedCollaborators
        }));
      })
      .catch(error => {
        console.error('Error adding collaborator:', error);
      });
    }
  };
  
  const handleRemoveCollaborator = (userId) => {
    if (selectedTask && selectedTask.collaborators?.includes(userId)) {
      const updatedCollaborators = selectedTask.collaborators.filter(id => id !== userId);
      dispatch(updateTask({
        userId: firebaseUser.uid,
        taskId: selectedTask.id,
        taskData: { collaborators: updatedCollaborators },
        taskCreatorId: selectedTask.taskCreatorId
      }));
    }
  };

  const filteredUsers = useMemo(() => {
    return users.filter(user =>
      user.email.toLowerCase().includes(collaboratorSearch.toLowerCase()) ||
      user.uid.toLowerCase().includes(collaboratorSearch.toLowerCase())
    );
  }, [users, collaboratorSearch]);

  return (
    <div className="fixed inset-0 bg-gray-900 text-white flex flex-col z-50">
      <Header cycleDesign={cycleDesign} currentDesign={currentDesign} onNewChatClick={handleNewChatClick} />
      <div className="flex-1 flex overflow-hidden">
        <div className={`${showSidebar ? 'w-64' : 'w-0'} bg-gray-800 flex flex-col overflow-hidden transition-all duration-300 ease-in-out`}>
          <div className="flex-1 overflow-y-auto p-4">
            <div className="mb-4">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search notes..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full bg-gray-700 text-white px-4 py-2 pr-10 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <SearchIcon className="h-5 w-5 absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
            </div>
            <button
              onClick={() => setShowNewNoteModal(true)}
              className="w-full bg-blue-600 text-white px-4 py-2 rounded-lg mb-4 flex items-center justify-center hover:bg-blue-700 transition-colors"
            >
              <PlusIcon className="h-5 w-5 mr-2" />
              New Note
            </button>
            <div className="space-y-2">
              {filteredTasks.map((task) => (
                <div key={task.id}>
                  <div
                    className={`p-3 rounded-lg cursor-pointer flex justify-between items-center transition-colors ${
                      selectedTask?.id === task.id ? 'bg-blue-600' : 'hover:bg-gray-700'
                    }`}
                    onClick={() => handleTaskClick(task)}
                  >
                    <h3 className="font-semibold">{task.title}</h3>
                    <div className="flex items-center space-x-2">
                      {task.subtasks && task.subtasks.length > 0 && (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleTaskExpansion(task.id);
                          }}
                          className="text-gray-400 hover:text-white"
                        >
                          {isTaskExpanded(task.id) ? (
                            <ChevronDownIcon className="h-5 w-5" />
                          ) : (
                            <ChevronRightIconSolid className="h-5 w-5" />
                          )}
                        </button>
                      )}
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteNote(task);
                        }}
                        className="text-red-400 hover:text-red-600"
                      >
                        <TrashIcon className="h-5 w-5" />
                      </button>
                    </div>
                  </div>
                  {isTaskExpanded(task.id) && Array.isArray(task.subtasks) && (
                    <div className="pl-6 space-y-1">
                      {task.subtasks.map((subtask, index) => (
                        <div
                          key={`${task.id}-subtask-${index}`}
                          className={`p-2 rounded-lg cursor-pointer transition-colors ${
                            selectedSubtask?.title === subtask.title ? 'bg-blue-500' : 'hover:bg-gray-700'
                          }`}
                          onClick={() => handleSubtaskClick(subtask)}
                        >
                          {subtask.title}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <button
            onClick={onClose}
            className="w-full bg-gray-700 text-white px-4 py-2 hover:bg-gray-600 transition-colors"
          >
            Close
          </button>
        </div>

        <div className="flex-1 flex flex-col overflow-hidden">
          <div className="bg-gray-800 p-4 flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => setShowSidebar(!showSidebar)} className="mr-4 text-gray-400 hover:text-white md:hidden">
                <MenuIcon className="h-6 w-6" />
              </button>
              <h2 className="text-2xl font-bold">{selectedTask ? selectedTask.title : 'Notebook'}</h2>
            </div>
            {isAdmin && selectedTask && firebaseUser && (
              <button
                onClick={() => setShowCollaboratorModal(true)}
                className="p-2 rounded-full hover:bg-gray-700 transition-colors"
              >
                <UserAddIcon className="h-5 w-5" />
              </button>
            )}
          </div>
          <div className="flex-1 p-6 overflow-y-auto">
            {selectedTask ? (
              <>
                {selectedTask.collaborators && selectedTask.collaborators.length > 0 && (
                  <div className="mb-4">
                    <h3 className="text-lg font-semibold mb-2">Collaborators:</h3>
                    <div className="flex flex-wrap gap-2">
                      {selectedTask.collaborators.map((userId) => {
                        const user = users.find((u) => u.uid === userId);
                        return (
                          <div key={userId} className="bg-gray-700 px-3 py-1 rounded-full flex items-center">
                            <span>{user ? user.email : 'Unknown User'} ({userId})</span>
                            {isAdmin && (
                              <button
                                onClick={() => handleRemoveCollaborator(userId)}
                                className="ml-2 text-red-400 hover:text-red-600"
                              >
                                <XIcon className="h-4 w-4" />
                              </button>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                <textarea
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  onBlur={handleSaveNotes}
                  className="w-full h-full bg-gray-800 text-white px-4 py-3 rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter your notes here..."
                />
              </>
            ) : (
              <div className="flex flex-col items-center justify-center h-full text-gray-400">
                <p className="text-xl mb-4 text-center">Select a note or create a new one to get started</p>
                <button onClick={() => setShowNewNoteModal(true)} className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors">
                  Create New Note
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {showNewNoteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-800 p-6 rounded-lg w-96 max-w-[90%]">
            <h3 className="text-xl font-semibold mb-4">Create New Note</h3>
            <input
              type="text"
              value={newNoteTitle}
              onChange={(e) => setNewNoteTitle(e.target.value)}
              placeholder="Enter note title"
              className="w-full bg-gray-700 text-white px-4 py-2 rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <div className="flex justify-end space-x-2">
              <button onClick={() => setShowNewNoteModal(false)} className="px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition-colors">
                Cancel
              </button>
              <button onClick={handleCreateNewNote} className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors">
                Create
              </button>
            </div>
          </div>
        </div>
      )}

      {showCollaboratorModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-800 p-6 rounded-lg w-96 max-w-[90%]">
            <h3 className="text-xl font-semibold mb-4">Add Collaborator</h3>
            <input
              type="text"
              placeholder="Search by email or user ID..."
              value={collaboratorSearch}
              onChange={(e) => setCollaboratorSearch(e.target.value)}
              className="w-full bg-gray-700 text-white px-4 py-2 mb-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <div className="max-h-60 overflow-y-auto">
              {filteredUsers.map(user => (
                <div key={user.uid} className="flex items-center justify-between py-2">
                  <span>{user.email} ({user.uid})</span>
                  {selectedTask?.collaborators?.includes(user.uid) ? (
                    <button
                      onClick={() => handleRemoveCollaborator(user.uid)}
                      className="bg-red-600 text-white px-3 py-1 rounded hover:bg-red-700 transition-colors"
                    >
                      Remove
                    </button>
                  ) : (
                    <button
                      onClick={() => handleAddCollaborator(user.uid)}
                      className="bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700 transition-colors"
                    >
                      Add
                    </button>
                  )}
                </div>
              ))}
            </div>
            <button
              onClick={() => setShowCollaboratorModal(false)}
              className="mt-4 w-full bg-gray-600 text-white px-4 py-2 rounded-lg hover:bg-gray-700 transition-colors"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Notebook;